import {Injectable} from '@angular/core';
import {TableDataService} from './tableData.service';
import {EmailModel} from '../DataModels/email-model';
import {AppConfigService} from './app-config.service';
import {NotificationService} from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
              public tableDataService: TableDataService,
              private appConfig: AppConfigService,
              private notification: NotificationService
              ) {
    // const mailObj = new EmailModel();
  }

  sendEmail(mailObj = null, silent = false) {
    // alert(JSON.stringify(mailObj));
    // console.log('at email post: ' + JSON.stringify(mailObj));

    // let it ignore preflights coming back so we don't get dup success messages
    this.tableDataService.post('email', mailObj).subscribe((result: { description: string, Message: string }) => {
      // console.log(result.Message);
      if (result.Message !== 'Success' && result.Message !== 'Preflight') {
        this.notification.warning(result.description);
      } else if (result.Message === 'Success' && !silent){
        // this.notification.success(result.description);
      }

    }, (error: { error: { Message: string }, statusText: string }) => {
      let ErrorMsg: string;
      if (typeof error.error.Message != 'undefined') {
        ErrorMsg = error.error.Message;
      }
      ErrorMsg = error.statusText;
      // this.notification.error(ErrorMsg);
    });
    // cleanup mail object that wants to hang around at times - no idea why
    // Object.keys(mailObj).forEach(key => delete mailObj[key]);

  }
}
