import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import { TableDataService } from '../../../Services/tableData.service';
import { StorageService } from '../../../Services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ArraySortPipe } from '../../Reports/transaction-reports/orderBy';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { DecimalNewPipe } from '../../common/DecimalNewPipe';
import { ConfirmDialogService } from '../../../Services/confirm-dialog.service';
import { CustomFilterPipe } from '../../admin/custom-filter.pipe';
import { PreferencesService } from '../../../Services/preferences.service';
import { SendEmailInfoModel } from '../../../DataModels/send-email-info-model';
import { ResponseModel } from '../../../DataModels/response-model';
import { EmailPassDataServiceService } from '../../../Services/email-pass-data-service.service';
import { BsToastService } from '../../../Services/bs-toast-service';
import { TaxEngineService } from '../../../Services/tax-engine.service';
import {take, tap} from 'rxjs/operators';
import { RoutingTrackerService } from '../../../Services/routing-tracker.service';
import { PrintService } from '../../../Services/print.service';
import {EmailModel} from '../../../DataModels/email-model';
import {Modal} from 'bootstrap';

@Component({
  selector: 'app-invoice-form',
  templateUrl: './invoice-form.component.html',
  styleUrls: ['./invoice-form.component.css'],
})

export class InvoiceFormComponent implements OnInit, AfterViewInit {


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: StorageService,
    private tableDataService: TableDataService,
    private notification: BsToastService,
    private datePipe: DatePipe,
    private decimal: DecimalPipe,
    private titleService: Title,
    private decimalPipe: DecimalNewPipe,
    private confirmDialogService: ConfirmDialogService,
    public cFilter: CustomFilterPipe,
    public pre: PreferencesService,
    private emailData: EmailPassDataServiceService,
    public taxEngine: TaxEngineService,
    public prevUrl: RoutingTrackerService,
    private printService: PrintService,
  ) {
    this.vm.item = {
      applied: [],
    };
    this.vm.Preferences = {};
    this.vm.shipmentAttachments = [];
    this.vm.linkedAttachments = [];
    this.vm.permissions = {};
    this.vm.account = [];
    this.vm.accountSubtotal = [];
    this.vm.uom = [];
    this.vm.bank = [];
    this.vm.allBanks = [];
    this.vm.company = [];
    this.vm.currency = [];
    this.vm.entity = [];
    this.vm.entityType = [];
    this.vm.inventoryItem = [];
    this.vm.location = [];
    this.vm.salesperson = [];
    this.vm.paymentType = [];
    this.vm.rfType = [];
    this.vm.rf1 = [];
    this.vm.rf2 = [];
    this.vm.rf3 = [];
    this.vm.rf4 = [];
    this.vm.rf5 = [];
    this.vm.tax = [];
    this.vm.taxRate = [];
    this.vm.term = [];
    this.vm.user = [];
    this.vm.payment = {};
    this.vm.payment.batch = {};
    this.vm.payment.header = {};
    this.vm.payment.detail = [];
    this.vm.inventoryItemFilter = {};
    this.vm.entityFilter = {};
    this.vm.searchRecentItems = {};
    this.vm.searchTopItems = {};
    this.vm.deleted = [];
    this.vm.brArrayForEmail = [];
    this.vm.item.applied = [];

    this.vm.is_init = false;
    this.vm.buttonsDisabled = false;
    this.disablePrintButton = false;
    this.disableEmailButton = false;
    this.vm.subLedger = '';
    this.vm.invoiceForm = true;
    this.vm.gItemsToOrderEmit = true;
    this.vm.getItemsToOrderEmit = false;

    const prevUrl2 = this.prevUrl.getPreviousUrl();

    if (prevUrl2 !== null && prevUrl2 !== undefined) {
      this.routedFrom = prevUrl2;
    } else {
      this.routedFrom = '';
    }
  }

  vm: any = {};
  invoiceId: string;
  documentId: string;
  batchId: any;
  shipmentId: string;
  quoteId: string;
  workorderId: string;
  action: string;
  confirmDelete: any;
  showDelete: any;
  developerView = false;
  isBankLoaded = false;
  isCompanySelected = false;
  buttonConfig: any = {
    save: true,
    close: true,
    reset: false,
    delete: true,
    saveNew: true,
    saveClose: true,
    print: true,
    email: true,
  };
  disablePrintButton = false;
  disableEmailButton = false;
  emailInfo = new SendEmailInfoModel();
  public urlParams: any = [];
  public itemPaid: boolean;
  public showBatches: boolean;
  submitted = false;
  routedFrom = '';
  newDoc = false;
  taxRate2 = [];
  batchType: any;

  // email stuff
  reportDataToSendInEmail: EmailModel = new EmailModel();
  emailReportDataFromAppInvoiceEmailEmit: any;
  emailDocumentName = '';

  @ViewChild('document', {static: false}) EmailData: ElementRef;
  @Output() emailPDFHtml: EventEmitter<ElementRef> = new EventEmitter();

  protected readonly window = window;

  ngOnInit() {

    this.route.queryParams.subscribe((params) => {
      if (this.vm.isLoaded) {
       // do stuff if needed
      }
      // this.vm.type = params.type;
      // this.vm.subLedger = params.type; // subledger same as type;
      // this.ngOnInit();
      this.invoiceId = params.invoiceId;
      this.documentId = params.documentId;
      this.batchId = params.batchId;
      this.shipmentId = params.shipmentId;
      this.quoteId = params.quoteId;
      this.workorderId = params.workorderId;
      this.action = params.action;
    });
    // this.urlParams = this.route.snapshot.queryParams;
    //
    // this.invoiceId = this.urlParams.invoiceId;
    // this.documentId = this.urlParams.documentId;
    // this.batchId = this.urlParams.batchId;
    // this.shipmentId = this.urlParams.shipmentId;
    // this.quoteId = this.urlParams.quoteId;
    // this.workorderId = this.urlParams.workorderId;
    // this.action = this.urlParams.action;
    this.vm.request = {};
    this.vm.paid = false;
    this.vm.isLoaded = false;

    // fix url thru attrition - change parameter to documentId over time -20240124 -lp
    if (!this.documentId && this.invoiceId) {
      this.documentId = this.invoiceId;
    }
    if (!this.documentId && this.quoteId) {
      this.documentId = this.quoteId;
    }
    this.initData();
    }

    initData() {
    if (this.documentId === 'newAP') {
      this.newDoc = true;
      this.vm.item = {};
      this.vm.buttonsDisabled = false;
      this.vm.item = { batchTypeId: '4' };
    } else if (this.documentId === 'newAR') {
      this.newDoc = true;
      this.vm.buttonsDisabled = false;
      this.vm.item = {};
      this.vm.item = { batchTypeId: '2' };
    } else if (this.quoteId === 'newQuote') {
      this.newDoc = true;
      this.vm.item = {};
      this.vm.buttonsDisabled = false;
      this.vm.item = { batchTypeId: '8' };
    } else if (this.documentId === 'newSalesOrder') {
      this.newDoc = true;
      this.vm.item = {};
      this.vm.buttonsDisabled = false;
      this.vm.item = { batchTypeId: '9' };
    } else if (this.documentId === 'newPurchaseOrder') {
      this.newDoc = true;
      this.vm.item = {};
      this.vm.buttonsDisabled = false;
      this.vm.item = { batchTypeId: '10' };
    } else {
      this.vm.is_init = true;
      this.tableDataService.getTableData('entry', this.documentId).subscribe({
        next: (result: any) => {
          const i = [];
          this.vm.item = result;
          // console.log('invoice form');
          // console.log(JSON.stringify(result));
          if (this.vm.item.batch.type_id == 2) {
            this.vm.subLedger = 'ar';
          } else if (this.vm.item.batch.type_id == 4) {
            this.vm.subLedger = 'ap';
          }

          this.init().then((y) => {
            this.addTextSignature();
          });
          // store a fake array to count thru for br in email send so it spaces out correctly
          let x: number;
          for (x = 0; x < 18 - this.vm.item.detail.length; x++) {
            this.vm.brArrayForEmail.push(i);
          }
          // console.log(this.vm.item);
          // for change batch modal
          this.batchType = this.vm.item.batch.type_id;
        },
        error: (err: any) => {
          console.error(err.message);
        },
        complete: () => {},
      });
    }

    if (!this.vm.item.header) {
      this.vm.item.header = {};
    }
    if (!this.vm.item.header.entityInput) {
      this.vm.item.header.entityInput = [];
    }
    if (!this.vm.item.header.comment) {
      this.vm.item.header.comment = [];
    }
    if (!this.vm.item.header.entityInput) {
      this.vm.item.header.entityInput = [];
    }
    if (!this.vm.item.detail) {
      this.vm.item.detail = [];
    }

    if (!this.vm.item.attachments) {
      this.vm.item.attachments = [];
    }
    if (!this.vm.item.batch) {
      this.vm.item.batch = {};
    }

    this.vm.auditTrail = true;
    this.vm.Preferences = JSON.parse(this.localStorage.StorageGet('Preferences'));
    this.vm.permissions = JSON.parse(this.localStorage.StorageGet('Permissions'));

    if (
      !this.vm.permissions.EditReceivableInvoices &&
      !this.vm.permissions.ViewReceivableInvoices &&
      !this.vm.permissions.EditPayableInvoices &&
      !this.vm.permissions.ViewPayableInvoices &&
      !this.vm.permissions.EditQuotes &&
      !this.vm.permissions.ViewQuotes &&
      !this.vm.permissions.EditSalesOrders &&
      !this.vm.permissions.ViewSalesOrders &&
      !this.vm.permissions.EditPurchaseOrders &&
      !this.vm.permissions.ViewPurchaseOrders &&
      !this.vm.permissions.Admin
    ) {
      document.location.href = '/';
    }

    this.vm.newEntity = {};
    this.vm.newAddress = {};
    this.vm.newContact = {};
    this.vm.newAccount = {};
    this.vm.newItem = {};
    this.vm.accountFilter = {};
    this.vm.printItem = { shipToAddress1: '', shipToAddress2: '' };
    this.vm.subLedger = '';
    this.vm.paid = false;
    this.vm.startInventoryItem =
      this.vm.startAccount =
      this.vm.startEntity =
      this.vm.invoiceSubTotal =
      this.vm.invoiceTaxTotal =
      this.vm.invoiceInvoiceTotal =
        0;
    this.vm.invoiceTaxes = [];
    this.vm.calcTaxAmount = false;
    this.vm.shipOrder = false;
    this.vm.orderShipped = false;
    this.vm.invoiceQuote = false;
    this.vm.orderQuote = false;
    this.vm.shipmentNumber = '';
    this.vm.history = {};
    this.vm.history.Pricing = [];
    this.vm.history.RecentItems = [];
    this.vm.history.TopItems = [];
    this.vm.history.Taxes = {};
    this.vm.history.Defaults = {};
    this.vm.emailed = false;
    this.vm.email = {};
    this.vm.email_message =
      'Hi [TONAME],\r\n \r\nPlease find attached invoice number [NUMBER].\r\n \r\nThank you,\r\n[FROMNAME]';
    this.vm.title = '';
    this.vm.inventoryItemFilter.search_string = null;
    this.vm.entityFilter.name = null;
    this.vm.tempItemDetailId = null;
    this.vm.tempAccountDetailId = null;
    this.vm.searchRecentItems.name = null;
    this.vm.searchTopItems.name = null;
    this.vm.accountFilter.type_name = null;
    this.vm.paymentAmount = null;
    this.vm.item.header.payment_applied_total = 0;
    this.vm.billToAddress = ' ';
    this.vm.shipToAddress = ' ';
    this.vm.contact = ' ';
    this.vm.isLoaded = false;

    // for modal
    this.vm.accountFilter.search_string = null;

    this.getTableData('location', '?state=active', 'location', (value) => {
      this.vm.location = value;
      this.vm.locationLoaded = true;
    }).then();

    this.tableDataService
      .getTableData('account', '?pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        // const data: any = result.Data;
        this.vm.account = result.Data;
        this.vm.accountName = [];
        for (const [y, val] of Object.entries(this.vm.account)) {
          const tempName = this.vm.account[y].number + ' ' + this.vm.account[y].name;
          this.vm.accountName.push({
            name: tempName,
          });
        }
      });

    this.getTableData('account_subtotal_no_parents', '', 'accountSubtotal', (value: any) => {
      this.vm.accountSubtotal = value;
    }).then();

    this.getTableData('entity', '?state=active', 'entity', (value: any) => {
      this.vm.entity = value;
      this.vm.entityLoaded = true;
    }).then();

    this.getTableData('entity_type', '?state=active', 'entityType', (value: any) => {
      this.vm.entityType = value;
    }).then();

    this.getTableData('uom', '?state=active', 'uom', (value: any) => {
      this.vm.uom = value;
    }).then();

    // if (!this.workorderId) {
    this.getTableData('bank', '?state=active', 'false', (value: any) => {
      this.vm.bank = value;
      this.vm.allBanks = value;
      this.isBankLoaded = true;
    }).then();
    // }

    this.getTableData('company', '?state=active', 'company', (value: any) => {
      this.vm.company = value;
      this.vm.companyLoaded = true;
    }).then();

    this.getTableData('currency', '?state=active', 'currency', (value: any) => {
      this.vm.currency = value;
      this.vm.currencyLoaded = true;
    }).then();

    this.getTableData('salesperson', '?state=active', 'salesperson', (value: any) => {
      this.vm.salesperson = value;
    }).then();

    this.getTableData('payment_type', '?state=active', 'paymentType', (value: any) => {
      this.vm.paymentType = value;
      this.vm.paymentTypeLoaded = true;
    }).then();

    this.getTableData('reporting_field_type', '?rfType&sorting=id asc', 'rf_type', (value: any) => {
      this.vm.rfType = value;
    }).then();

    this.getTableData('reporting_field', '?type_id=rf1', 'rf1', (value: any) => {
      this.vm.rf1 = value;
    }).then();

    this.getTableData('reporting_field', '?type_id=rf2', 'rf2', (value: any) => {
      this.vm.rf2 = value;
    }).then();

    this.getTableData('reporting_field', '?type_id=rf3', 'rf3', (value: any) => {
      this.vm.rf3 = value;
    }).then();

    this.getTableData('reporting_field', '?type_id=rf4', 'rf4', (value: any) => {
      this.vm.rf4 = value;
    }).then();

    this.getTableData('reporting_field', '?type_id=rf5', 'rf5', (value: any) => {
      this.vm.rf5 = value;
    }).then();

    this.getTableData('tax', '?state=active', 'tax', (value: any) => {
      this.vm.tax = value;
    }).then();

    this.getTableData('tax_rate', '?state=active', '', (value: any) => {
      this.vm.taxRate = value;
    }).then();

    this.getTableData('term', '?state=active', 'term', (value: any) => {
      this.vm.term = value;
    }).then();

    this.getTableData('sys_user', '?state=active', 'user', (value: any) => {
      this.vm.user = value;
    }).then();

    this.vm.inventoryItemInsertQty = 1;

    if (!this.vm.is_init) {
      this.init().then(() => {});
    }

    // this.signaturetext();
    // });
    this.disablePrintButton = false;
    this.disableEmailButton = false;
    this.calcTotal();


  }

  ngAfterViewInit() {
    this.titleService.setTitle(this.vm.title);
  }

  getDataEmitFromInvoiceToEmail(event: ElementRef) {
    // console.log('in invoice-form - email pdf emit fired from invoice-email:' + event);
    this.emailReportDataFromAppInvoiceEmailEmit = event;
  }

  getDocumentOnSave(id: string) {
    this.tableDataService.getTableData('entry', id).subscribe({
      next: (result: any) => {
        const i = [];
        this.vm.item = result;
        // console.log('invoice form');
        // console.log(JSON.stringify(result));
        if (this.vm.item.batch.type_id == 2) {
          this.vm.subLedger = 'ar';
        } else if (this.vm.item.batch.type_id == 4) {
          this.vm.subLedger = 'ap';
        }

        this.init().then((y) => {
          this.addTextSignature();
        });
        // store a fake array to count thru for br in email send so it spaces out correctly
        let x: number;
        for (x = 0; x < 18 - this.vm.item.detail.length; x++) {
          this.vm.brArrayForEmail.push(i);
        }
        // console.log(this.vm.item);
        // for change batch modal
        this.batchType = this.vm.item.batch.type_id;
      },
      error: (err: any) => {
        console.error(err.message);
      },
      complete: () => {},
    });
  }


  paymentAmountCalc() {
    if (!this.vm.item.header.payment_applied_total) {
      this.vm.item.header.payment_applied_total = 0;
    }
    if (this.vm.total - this.vm.item.header.payment_applied_total) {
      this.vm.paymentAmount = this.decimalPipe.transform(this.vm.total - this.vm.item.header.payment_applied_total);
    } else {
      this.vm.paymentAmount = '';
    }
  }

  async getTableDataAsync(tableName: string, params: string, cacheName, fun) {
    const cache = false;
    if (cache) {
    } else {
      await this.tableDataService
        .getTableData(tableName, params + '&pageNumber=0&pageSize=99999')
        .toPromise()
        .then((result: any) => {
          fun(result.Data);
        });
    }
  }

  async init() {
    // console.log('init method');
    await this.loadItem();
    // NEW ENTRY
    if (this.vm.item.batchTypeId == '2') {
      this.vm.title =
        $localize`:Receivable Invoice @@receivableinvoice:Receivable Invoice`;
      this.vm.subLedger = 'ar';
      this.vm.invoiceTypeName = $localize`:Receivable @@receivable:Receivable`;
      this.vm.invoiceTypeClass = ' receivables ';
      this.vm.accountFilter.type_name = 'Revenue';
      this.vm.newEntity.receivable = true;
      this.vm.payment = { batchTypeId: 3 };
      this.vm.payment.batch = { type_id: 3, description: 'Batch' };
      this.vm.payment.header = {};
      this.vm.payment.detail = [{ id: 1 }];
    } else if (this.vm.item.batchTypeId == '4') {
      this.vm.title = $localize`:Payable Invoice @@payableInvoice:Payable Invoice` + ' ';
      this.vm.subLedger = 'ap';
      this.vm.invoiceTypeName = $localize`:Payable @@payable:Payable`;
      this.vm.invoiceTypeClass = ' payables ';
      this.vm.accountFilter.type_name = 'Expenses';
      this.vm.newEntity.payable = true;
      this.vm.payment = { batchTypeId: 5 };
      this.vm.payment.batch = { type_id: 5, description: 'Batch' };
      this.vm.payment.header = {};
      this.vm.payment.detail = [{ id: 1 }];
    } else if (this.vm.item.batchTypeId == '8') {
      this.vm.title = $localize`:Quote @@quote:Quote` + ' *** ' + $localize`:NEW @@nEW:NEW` + ' ***';
      this.vm.subLedger = 'quotes';
      this.vm.invoiceTypeName = $localize`:Quote @@quote:Quote`;
      this.vm.invoiceTypeClass = ' receivables ';
      this.vm.accountFilter.type_name = 'Revenue';
      this.vm.newEntity.receivable = true;
      this.vm.payment = { batchTypeId: 3 };
      this.vm.payment.batch = { type_id: 3, description: 'Batch' };
      this.vm.payment.header = {};
      this.vm.payment.detail = [{ id: 1 }];
    } else if (this.vm.item.batchTypeId == '9') {
      this.vm.title = $localize`:Sales Order @@salesOrder:Sales Order` + ' *** ' + $localize`:NEW @@nEW:NEW` + ' ***';
      this.vm.subLedger = 'salesorders';
      this.vm.invoiceTypeName = $localize`:Sales Order @@salesOrder:Sales Order`;
      this.vm.invoiceTypeClass = ' receivables ';
      this.vm.accountFilter.type_name = 'Revenue';
      this.vm.newEntity.receivable = true;
      this.vm.payment = { batchTypeId: 3 };
      this.vm.payment.batch = { type_id: 3, description: 'Batch' };
      this.vm.payment.header = {};
      this.vm.payment.detail = [{ id: 1 }];
    } else if (this.vm.item.batchTypeId == '10') {
      this.vm.title =
        $localize`:Purchase Order @@purchaseOrder:Purchase Order` + ' *** ' + $localize`:NEW @@nEW:NEW` + ' ***';
      this.vm.subLedger = 'purchaseorders';
      this.vm.invoiceTypeName = $localize`:Purchase Order @@purchaseOrder:Purchase Order` + ' ';
      this.vm.invoiceTypeClass = ' payables ';
      this.vm.accountFilter.type_name = 'Expenses';
      this.vm.newEntity.payable = true;
      this.vm.payment = { batchTypeId: 5 };
      this.vm.payment.batch = { type_id: 5, description: 'Batch' };
      this.vm.payment.header = {};
      this.vm.payment.detail = [{ id: 1 }];
      this.vm.billToAddress = 'Bill To Address';
      this.vm.shipToAddress = 'Ship To Address';
    }
    this.titleService.setTitle(this.vm.title);
    if (this.vm.item.batchTypeId) {
      if (this.shipmentId) {
        // console.log('getting shipment');
        this.tableDataService.getTableData('entry', this.shipmentId).subscribe(async (tempShipment: any) => {
          const tempBatchTypeId = this.vm.item.batchTypeId;
          this.vm.item = tempShipment;
          this.vm.linkedSignature = tempShipment.header.signature;
          this.vm.item.header.id = undefined;
          this.vm.item.header.audit_sequence =
            this.documentId === 'newAP' || this.documentId === 'newAR' ? undefined : this.vm.item.header.audit_sequence;
          this.vm.item.header.completed =
            this.documentId === 'newAP' || this.documentId === 'newAR' ? undefined : this.vm.item.header.completed;
          this.vm.shipmentNumber = this.vm.item.header.number;
          this.vm.item.header.number = undefined;
          this.vm.item.header.signature = undefined;
          this.vm.item.header.linked_header_id = this.shipmentId;
          this.vm.item.header.batch_id = undefined;
          this.vm.item.batch.id = undefined;
          this.vm.item.batch.type_id = tempBatchTypeId;
          // this.vm.item.header.header_date = this.datePipe.transform(this.vm.item.header.header_date, 'MM/dd/yyyy');
          this.vm.item.header.header_date = this.datePipe.transform(new Date(), 'MM/dd/yyy');
          this.vm.linkedAttachments = this.vm.item.attachments;
          this.vm.item.attachments = []; // clear double save of shipment images
          // get entity name
          let paramString = '';
          if (this.vm.item.header.entity_id && this.vm.entity) {
            if (
              1 === 1
              // this.FilterObject(
              //   this.vm.entity,
              //   {id: this.vm.item.header.entity_id},
              //   true
              // )[0]
            ) {
              this.vm.item.header.entityInput = this.FilterObject(
                this.vm.entity,
                { id: this.vm.item.header.entity_id },
                true
              )[0].name;
              this.vm.email.ToEmail = this.FilterObject(
                this.vm.entity,
                { id: this.vm.item.header.entity_id },
                true
              )[0].email;

              paramString = '';
              paramString += 'billing_address=1';
              paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
              paramString += '&pageNumber=0';
              paramString += '&pageSize=999999';

              await this.tableDataService
                .getTableData('entity_address/addresses', '?' + paramString)
                .subscribe((result: ResponseModel) => {
                  // const data: any = result.Data;
                  this.vm.billToAddress = result.Data;
                  if (this.vm.billToAddress.length == 1) {
                    this.vm.item.header.bill_address_id = this.vm.billToAddress[0].id;
                  }
                });

              paramString = '';
              paramString += 'billing_address=0';
              paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
              paramString += '&pageNumber=0';
              paramString += '&pageSize=999999';

              await this.tableDataService
                .getTableData('entity_address/addresses', '?' + paramString)
                .subscribe((result: ResponseModel) => {
                  // const data: any = result.Data;
                  this.vm.shipToAddress = result.Data;
                  if (this.vm.shipToAddress.length == 1) {
                    this.vm.item.header.ship_address_id = this.vm.shipToAddress[0].id;
                  }
                });

              // this.getContact();
              // this.getHistory();
            } else {
              const badEntity = this.vm.entity.filter((x) => x.id === this.vm.item.header.entity_id);
              if (!badEntity.length) {
                this.notification.showWarningToast(
                  $localize`:The existing entity is no longer valid. @@existingEntityInvalid:The existing entity is no longer valid`
                );
              }
              // error entity is no longer the correct receivable or payable entity
              this.vm.entityError = true;
              this.vm.item.header.entity_id = '';
            }
          }
          // get item codes
          for (const [x, value] of Object.entries(this.vm.item.detail)) {
            this.vm.item.detail[x].quantity = Number(this.vm.item.detail[x].quantity);
            if (this.vm.item.detail[x].account_id == 'in') {
              this.vm.item.detail[x].accountInput = 'Inventory';
              // this.vm.item.detail[x].disableAccount = true;
            }
            if (this.vm.item.detail[x].item_id) {
              if (this.FilterObject(this.vm.inventoryItem, { id: this.vm.item.detail[x].item_id }, true).length > 0) {
                this.vm.item.detail[x].itemInput = this.FilterObject(
                  this.vm.inventoryItem,
                  { id: this.vm.item.detail[x].item_id },
                  true
                )[0].code;
              }
            }
            if (this.FilterObject(this.vm.account, { id: this.vm.item.detail[x].account_id }, true).length > 0) {
              this.vm.item.detail[x].accountInput =
                this.FilterObject(this.vm.account, { id: this.vm.item.detail[x].account_id }, true)[0].number +
                ' ' +
                this.FilterObject(this.vm.account, { id: this.vm.item.detail[x].account_id }, true)[0].name;
            }
          }
          for (let x = this.vm.item.detail.length - 1; x >= 0; --x) {
            if (this.vm.item.detail[x].quantity == 0) {
              this.vm.item.detail.splice(x, 1);
            }
          }

          this.calcTotal();
          this.setInitialFormValues();
          this.spliceEntities();
          // console.log('this.vm.item from get shipment: ' + JSON.stringify(this.vm.item));
        });
      } else if (this.quoteId && !this.quoteId.includes('new')) {
        console.log('q');
        this.tableDataService.getTableData('entry', this.quoteId).subscribe((tempQuote: any) => {
          const tempBatchTypeId = this.vm.item.batchTypeId;
          this.vm.item = tempQuote;
          this.vm.item.header.id = undefined;
          this.vm.quoteNumber = this.vm.item.header.number;
          this.vm.item.header.number = undefined;
          this.vm.item.header.audit_sequence =
            this.documentId === 'newAP' || this.documentId === 'newAR' ? undefined : this.vm.item.header.audit_sequence;
          this.vm.item.header.completed =
            this.documentId === 'newAP' || this.documentId === 'newAR' ? undefined : this.vm.item.header.completed;
          this.vm.item.header.signature = undefined;
          this.vm.item.header.linked_header_id = this.quoteId;
          this.vm.item.header.batch_id = undefined;
          this.vm.item.batch.id = undefined;
          this.vm.item.batch.type_id = tempBatchTypeId;
          // this.vm.item.header.header_date = this.datePipe.transform(this.vm.item.header.header_date, 'MM/dd/yyyy');
          this.vm.item.header.header_date = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
          this.vm.linkedAttachments = this.vm.item.attachments;
          this.vm.item.attachments = []; // clear double save of shipment images
          // get entity name
          let paramString = '';
          if (this.vm.item.header.entity_id && this.vm.entity) {
            if (this.FilterObject(this.vm.entity, { id: this.vm.item.header.entity_id }, true)[0]) {
              this.vm.item.header.entityInput = this.FilterObject(
                this.vm.entity,
                { id: this.vm.item.header.entity_id },
                true
              )[0].name;
              this.vm.email.ToEmail = this.FilterObject(
                this.vm.entity,
                { id: this.vm.item.header.entity_id },
                true
              )[0].email;

              paramString = '';
              paramString += 'billing_address=1';
              paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
              paramString += '&pageNumber=0';
              paramString += '&pageSize=999999';

              this.tableDataService
                .getTableData('entity_address/addresses', '?' + paramString)
                .subscribe((result: ResponseModel) => {
                  // const data: any = result.Data;
                  this.vm.billToAddress = result.Data;
                  if (this.vm.billToAddress.length == 1) {
                    this.vm.item.header.bill_address_id = this.vm.billToAddress[0].id;
                  }
                });

              paramString = '';
              paramString += 'billing_address=0';
              paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
              paramString += '&pageNumber=0';
              paramString += '&pageSize=999999';

              this.tableDataService
                .getTableData('entity_address/addresses', '?' + paramString)
                .subscribe((result: ResponseModel) => {
                  // const data: any = result.Data;
                  this.vm.shipToAddress = result.Data;
                  if (this.vm.shipToAddress.length == 1) {
                    this.vm.item.header.ship_address_id = this.vm.shipToAddress[0].id;
                  }
                });

              // this.getContact();
              // this.getHistory();
            } else {
              this.notification.showErrorToast(
                $localize`:The existing entity is no longer valid, please select another entity. @@entityInvalidPleaseSelectAnotherEntity:The existing entity is no longer valid, please select another entity.` +
                  '  '
              );
              // error entity is no longer the correct receivable or payable
              this.vm.entityError = true;
              this.vm.item.header.entity_id = '';
            }
          }
          // get item codes
          for (const [x, value] of Object.entries(this.vm.item.detail)) {
            this.vm.item.detail[x].quantity = Number(this.vm.item.detail[x].quantity);
            if (this.vm.item.detail[x].account_id == 'in') {
              this.vm.item.detail[x].accountInput = 'Inventory';
              // this.vm.item.detail[x].disableAccount = true;
            }
            if (this.vm.item.detail[x].item_id) {
              if (this.FilterObject(this.vm.inventoryItem, { id: this.vm.item.detail[x].item_id }, true).length > 0) {
                this.vm.item.detail[x].itemInput = this.FilterObject(
                  this.vm.inventoryItem,
                  { id: this.vm.item.detail[x].item_id },
                  true
                )[0].code;
              }
            }
            if (this.FilterObject(this.vm.account, { id: this.vm.item.detail[x].account_id }, true).length > 0) {
              this.vm.item.detail[x].accountInput =
                this.FilterObject(this.vm.account, { id: this.vm.item.detail[x].account_id }, true)[0].number +
                ' ' +
                this.FilterObject(this.vm.account, { id: this.vm.item.detail[x].account_id }, true)[0].name;
            }
          }
          this.calcTotal();
          this.setInitialFormValues();
          this.spliceEntities();
        });
      } else if (this.workorderId) {
        this.tableDataService.getTableData('WorkOrder', this.workorderId).subscribe((tempWorkOrder: any) => {
          const tempBatchTypeId = this.vm.item.batchTypeId;
          this.vm.workorderNumber = tempWorkOrder.wo_header.number;
          this.vm.linkedSignature = tempWorkOrder.wo_header.signature;
          this.vm.item.batch = {};
          this.vm.item.header = {};
          this.vm.item.detail = [];
          this.vm.item.header.workorder_id = this.workorderId;
          this.vm.item.batch.type_id = tempBatchTypeId;
          this.vm.item.header.audit_sequence =
            this.documentId === 'newAP' || this.documentId === 'newAR' ? undefined : this.vm.item.header.audit_sequence;
          this.vm.item.header.completed =
            this.documentId === 'newAP' || this.documentId === 'newAR' ? undefined : this.vm.item.header.completed;
          // this.vm.item.header.header_date = this.datePipe.transform(
          //   tempWorkOrder.wo_header.work_order_date,
          //   'MM/dd/yyyy'
          // );
          this.vm.item.header.header_date = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
          this.vm.item.header.entity_id = tempWorkOrder.wo_header.entity_id;
          this.vm.item.header.ship_address_id = tempWorkOrder.wo_header.address_id;
          this.vm.linkedAttachments = tempWorkOrder.wo_attachments;
          this.vm.item.header.company_id = tempWorkOrder.wo_header.company_id;
          this.changeCompany();

          // get entity name
          let paramString = '';
          if (this.vm.item.header.entity_id && this.vm.entity) {
            if (this.FilterObject(this.vm.entity, { id: this.vm.item.header.entity_id }, true)[0]) {
              this.vm.item.header.entityInput = this.FilterObject(
                this.vm.entity,
                { id: this.vm.item.header.entity_id },
                true
              )[0].name;
              this.vm.email.ToEmail = this.FilterObject(
                this.vm.entity,
                { id: this.vm.item.header.entity_id },
                true
              )[0].email;

              paramString = '';
              paramString += 'billing_address=1';
              paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
              paramString += '&pageNumber=0';
              paramString += '&pageSize=999999';

              this.tableDataService
                .getTableData('entity_address/addresses', '?' + paramString)
                .subscribe((result: ResponseModel) => {
                  // const data: any = result.Data;
                  this.vm.billToAddress = result.Data;
                  if (this.vm.billToAddress.length == 1) {
                    this.vm.item.header.bill_address_id = this.vm.billToAddress[0].id;
                  }
                });

              paramString = '';
              paramString += 'billing_address=0';
              paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
              paramString += '&pageNumber=0';
              paramString += '&pageSize=999999';

              this.tableDataService
                .getTableData('entity_address/addresses', '?' + paramString)
                .subscribe((result: ResponseModel) => {
                  // const data: any = result.Data;
                  this.vm.shipToAddress = result.Data;
                  if (this.vm.shipToAddress.length == 1) {
                    this.vm.item.header.ship_address_id = this.vm.shipToAddress[0].id;
                  }
                });

              // this.getContact();
              // getHistory();
            } else {
              this.notification.showErrorToast(
                $localize`:The existing entity is no longer valid, please select another entity. @@entityInvalidPleaseSelectAnotherEntity:The existing entity is no longer valid, please select another entity.` +
                  '  '
              );
              // error entity is no longer the correct receivable or payable
              this.vm.entityError = true;
              this.vm.item.header.entity_id = '';
            }
          }

          // need entity history for taxes and pricing prior to building rows.
          this.tableDataService
            .getTableData('entity_history', this.vm.item.header.entity_id)
            .subscribe((result: any) => {
              this.vm.history = result;
              // build details
              let sortOrder = 0;
              for (const [a, value] of Object.entries(tempWorkOrder.wo_items)) {
                const tempItem = this.FilterObject(
                  this.vm.inventoryItem,
                  { id: tempWorkOrder.wo_items[a].item_id },
                  true
                )[0];
                this.vm.item.detail.push({
                  item_id: tempWorkOrder.wo_items[a].item_id,
                  quantity: tempWorkOrder.wo_items[a].quantity,
                  sort_order: sortOrder,
                  itemInput: tempItem.code,
                  id: sortOrder,
                });
                this.selectItemDropdown(sortOrder);
                sortOrder++;
              }

              tempWorkOrder.wo_hours = this.OrderBy(tempWorkOrder.wo_hours, 'date', false);
              for (const [x, value] of Object.entries(tempWorkOrder.wo_hours)) {
                let tempUser: any = {};
                if (this.FilterObject(this.vm.user, { id: tempWorkOrder.wo_hours[x].user_id }, true).length > 0) {
                  tempUser = this.FilterObject(this.vm.user, { id: tempWorkOrder.wo_hours[x].user_id }, true)[0];
                }
                const tempDate = this.datePipe.transform(tempWorkOrder.wo_hours[x].date, 'MM/dd/yyyy');
                let tempDescription = '';
                if (tempWorkOrder.wo_hours[x].description != null) {
                  tempDescription = ' - ' + tempWorkOrder.wo_hours[x].description;
                }
                this.vm.item.detail.push({
                  id: sortOrder,
                  description: 'Hours: ' + tempUser.name + tempDescription + ' - ' + tempDate,
                  quantity: tempWorkOrder.wo_hours[x].hours,
                  amount: tempUser.bill_rate,
                  sort_order: sortOrder,
                });
                sortOrder++;
              }

              tempWorkOrder.wo_travel = this.OrderBy(tempWorkOrder.wo_travel, 'date', false);
              for (let x = 0; x < tempWorkOrder.wo_travel.length; x++) {
                let tempUser: any = {};
                if (this.FilterObject(this.vm.user, { id: tempWorkOrder.wo_hours[x].user_id }, true).length > 0) {
                  tempUser = this.FilterObject(this.vm.user, { id: tempWorkOrder.wo_hours[x].user_id }, true)[0];
                }
                const tempDate = this.datePipe.transform(tempWorkOrder.wo_travel[x].date, 'MM/dd/yyyy');
                let tempDescription = '';
                if (tempWorkOrder.wo_travel[x].description != null) {
                  tempDescription = ' - ' + tempWorkOrder.wo_travel[x].description;
                }
                this.vm.item.detail.push({
                  id: sortOrder,
                  description:
                    $localize`:Travel @@travel:Travel` + ': ' + tempUser.name + tempDescription + ' - ' + tempDate,
                  quantity: tempWorkOrder.wo_travel[x].distance,
                  amount: tempUser.travel_rate,
                  sort_order: sortOrder,
                });
                sortOrder++;
              }

              if (tempWorkOrder.wo_notes.length > 0 || tempWorkOrder.wo_header.description) {
                this.vm.item.header.comment =
                  $localize`:Work Order @@workOrder:Work Order` +
                  ' ' +
                  tempWorkOrder.wo_header.number +
                  ' ' +
                  $localize`:Notes @@notes:Notes` +
                  ':\r\n';
                if (tempWorkOrder.wo_header.description) {
                  this.vm.item.header.comment += tempWorkOrder.wo_header.description + '\r\n';
                }
              }
              if (tempWorkOrder.wo_notes.length > 0) {
                tempWorkOrder.wo_notes = this.OrderBy(tempWorkOrder.wo_notes, 'date', false);
                for (const [x, value] of Object.entries(tempWorkOrder.wo_notes)) {
                  const tempDate = this.datePipe.transform(tempWorkOrder.wo_notes[x].date, 'MM/dd/yyyy');
                  this.vm.item.header.comment += tempDate + ': ' + tempWorkOrder.wo_notes[x].note + '\r\n';
                }
              }
              this.calcTotal();
              this.setInitialFormValues();
              this.applyDefaultSettingsFromEntity();
            });
          this.spliceEntities();
        });
      } else {
        this.vm.item.batch = {};
        this.vm.item.batch.type_id = this.vm.item.batchTypeId; // '2';
        if (this.batchId) {
          this.vm.batchId = this.vm.item.batch.id = this.batchId;
        } else {
          this.vm.item.batch.description = 'Batch';
        }
        this.vm.item.header = {};
        this.vm.item.header.currency_rate = '1';
        this.vm.item.detail = [];
        this.vm.item.salespeople = [];

        const d = new Date();
        this.vm.item.header.header_date = this.datePipe.transform(d, 'MM/dd/yyyy');
        this.setInitialFormValues();
        this.spliceEntities();

        if (
          this.vm.Preferences.default_company_id &&
          this.FilterObject(this.vm.company, { id: this.vm.Preferences.default_company_id }, true)[0]
        ) {
          this.vm.item.header.company_id = this.vm.Preferences.default_company_id;
          this.changeCompany();
        }
      }
    } else {
      // EDIT ENTRY
      // this.vm.item.header.payment_applied_total = 100;
      this.vm.email_message = this.vm.email_message.replace('[NUMBER]', this.vm.item.header.number);
      // console.log('recur value before eval:' + this.vm.item.header.recurring );
      if (this.vm.item.header.recurring == 1) {
        this.vm.item.header.recurring = true;
      } else {
        this.vm.item.header.recurring = false;
      }
      // console.log('recur value after eval:' + this.vm.item.header.recurring );
      if (this.vm.item.batch.type_id == '2') {
        this.vm.title = 'Receivable Invoice ' + this.vm.item.header.number;
        this.vm.subLedger = 'ar';
        this.vm.invoiceTypeName = $localize`:Receivable @@receivable:Receivable`;
        this.vm.invoiceTypeClass = ' receivables ';
        this.vm.accountFilter.type_name = 'Revenue';
        this.vm.newEntity.receivable = true;
        this.vm.payment = { batchTypeId: 3 };
        this.vm.payment.batch = { type_id: 3, description: 'Batch' };
        this.vm.payment.header = {};
        this.vm.payment.detail = [{ id: 1 }];
        this.vm.linkedAttachments = [];
        if (this.vm.item.header.linked_header_id) {
          this.tableDataService
            .getTableData('entry', this.vm.item.header.linked_header_id)
            .subscribe(async (tempLinked: any) => {
              if (tempLinked.header) {
                // if (this.action == 'print') {
                //   this.tableDataService
                //     .getTableData('uom')
                //     .toPromise()
                //     .then((result: any) => {
                //       this.print();
                //     });
                // }
                this.vm.invoiceShipped = true;
                this.vm.shipmentNumber = tempLinked.header.number;
                this.vm.linkedSignature = tempLinked.header.signature;
                this.vm.shipmentId = tempLinked.header.id;
                for (const [x, value] of Object.entries(tempLinked.attachments)) {
                  this.vm.linkedAttachments.push({
                    attachment: tempLinked.attachments[x].attachment,
                    audit_sequence: tempLinked.attachments[x].audit_sequence,
                    source: 'Shipment: ' + this.vm.shipmentNumber,
                  });
                }

                await this.tableDataService
                  .getTableData('entry', tempLinked.header.linked_header_id)
                  .subscribe((tempLinked2: any) => {
                    if (tempLinked2.header.id) {
                      this.vm.orderNumber = tempLinked2.header.number;
                      this.vm.orderId = tempLinked2.header.id;
                      for (const [x, value] of Object.entries(tempLinked2.attachments)) {
                        this.vm.linkedAttachments.push({
                          attachment: tempLinked2.attachments[x].attachment,
                          audit_sequence: tempLinked2.attachments[x].audit_sequence,
                          source: 'Order: ' + this.vm.orderNumber,
                        });
                      }
                    }
                  });
              }
            });
        }
        if (this.vm.item.header.workorder_id) {
          this.tableDataService
            .getTableData('workorder', this.vm.item.header.workorder_id)
            .subscribe((tempLinked: any) => {
              if (tempLinked.wo_header) {
                this.vm.workorderNumber = tempLinked.wo_header.number;
                this.vm.linkedSignature = tempLinked.wo_header.signature;
                this.vm.workorderId = tempLinked.wo_header.id;
                for (const [x, value] of Object.entries(tempLinked.wo_attachments)) {
                  this.vm.linkedAttachments.push({
                    attachment: tempLinked.wo_attachments[x].attachment,
                    audit_sequence: tempLinked.wo_attachments[x].audit_sequence,
                    source: 'Work Order: ' + this.vm.workorderNumber,
                  });
                }
              }
            });
        }
      } else if (this.vm.item.batch.type_id == '4') {
        this.vm.title = 'Payable Invoice ' + this.vm.item.header.number;
        this.vm.subLedger = 'ap';
        this.vm.invoiceTypeName = $localize`:Payable @@payable:Payable`;
        this.vm.invoiceTypeClass = ' payables ';
        this.vm.accountFilter.type_name = 'Expenses';
        this.vm.newEntity.payable = true;
        this.vm.payment = { batchTypeId: 5 };
        this.vm.payment.batch = { type_id: 5, description: 'Batch' };
        this.vm.payment.header = {};
        this.vm.payment.detail = [{ id: 1 }];
        if (this.vm.item.header.linked_header_id) {
          this.tableDataService
            .getTableData('entry', this.vm.item.header.linked_header_id)
            .subscribe((tempLinked: any) => {
              if (tempLinked.header) {
                this.vm.invoiceShipped = true;
                this.vm.shipmentNumber = tempLinked.header.number;
                this.vm.linkedSignature = tempLinked.header.signature;
                this.vm.shipmentId = tempLinked.header.id;

                this.tableDataService
                  .getTableData('entry', tempLinked.header.linked_header_id)
                  .subscribe((tempLinked2: any) => {
                    if (tempLinked2.header.id) {
                      this.vm.orderNumber = tempLinked2.header.number;
                      this.vm.orderId = tempLinked2.header.id;
                    }
                  });
              }
            });
        }
      } else if (this.vm.item.batch.type_id == '8') {
        this.vm.title = 'Quote ' + this.vm.item.header.number;
        this.vm.subLedger = 'quotes';
        this.vm.item.header.recurring = false;
        this.vm.invoiceTypeName = $localize`:Quote @@quote:Quote`;
        this.vm.invoiceTypeClass = ' receivables ';
        this.vm.accountFilter.type_name = 'Revenue';
        this.vm.newEntity.receivable = true;
        this.vm.payment = { batchTypeId: 3 };
        this.vm.payment.batch = { type_id: 3, description: 'Batch' };
        this.vm.payment.header = {};
        this.vm.payment.detail = [{ id: 1 }];
        this.vm.email_message = this.vm.email_message.replace('invoice', 'quote');
      } else if (this.vm.item.batch.type_id == '9') {
        this.vm.title = 'Sales Order ' + this.vm.item.header.number;
        this.vm.subLedger = 'salesorders';
        this.vm.invoiceTypeName = $localize`:Sales Order @@salesOrder:Sales Order`;
        this.vm.invoiceTypeClass = ' receivables ';
        this.vm.accountFilter.type_name = 'Revenue';
        this.vm.newEntity.receivable = true;
        this.vm.payment = { batchTypeId: 3 };
        this.vm.payment.batch = { type_id: 3, description: 'Batch' };
        this.vm.payment.header = {};
        this.vm.payment.detail = [{ id: 1 }];
        this.vm.email_message = this.vm.email_message.replace('invoice', 'sales order');

        this.tableDataService.getTableData('entry', this.vm.item.header.id).subscribe((tempLinked: any) => {
          if (tempLinked.id) {
            this.vm.orderShipped = true;
          }
        });
      } else if (this.vm.item.batch.type_id == '10') {
        this.vm.title = 'Purchase Order ' + this.vm.item.header.number;
        this.vm.subLedger = 'purchaseorders';
        this.vm.invoiceTypeName = $localize`:Purchase Order @@purchaseOrder:Purchase Order` + ' ';
        this.vm.invoiceTypeClass = ' payables ';
        this.vm.accountFilter.type_name = 'Expenses';
        this.vm.newEntity.payable = true;
        this.vm.payment = { batchTypeId: 5 };
        this.vm.payment.batch = { type_id: 5, description: 'Batch' };
        this.vm.payment.header = {};
        this.vm.payment.detail = [{ id: 1 }];
        this.vm.email_message = this.vm.email_message.replace('invoice', 'purchase order');

        this.tableDataService.getTableData('entry', this.vm.item.header.id).subscribe((tempLinked: any) => {
          if (tempLinked.id) {
            this.vm.orderShipped = true;
          }
        });
      }

      this.spliceEntities();
      if (this.vm.item.batch.locked == true) {
        // this.vm.paid = true;
      }
      let paramString = '';
      // TODO this randomly falls thru at times. Appears to be a timing issue and most likely need to subscribe to fix. researching.
      if (this.vm.item.header.entity_id && this.vm.entity) {
        if (this.FilterObject(this.vm.entity, { id: this.vm.item.header.entity_id }, true)[0]) {
          this.vm.item.header.entityInput = this.FilterObject(
            this.vm.entity,
            { id: this.vm.item.header.entity_id },
            true
          )[0].name;
          this.vm.email.ToEmail = this.FilterObject(
            this.vm.entity,
            { id: this.vm.item.header.entity_id },
            true
          )[0].email;
          paramString = '';
          paramString += 'billing_address=1';
          paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
          paramString += '&pageNumber=0';
          paramString += '&pageSize=999999';

          this.tableDataService
            .getTableData('entity_address/addresses', '?' + paramString)
            .subscribe((result: ResponseModel) => {
              // const data: any = result.Data;
              this.vm.billToAddress = result.Data;
              if (this.vm.billToAddress.length == '1') {
                this.vm.item.header.bill_address_id = this.vm.billToAddress[0].id;
              }
            });

          paramString = '';
          paramString += 'billing_address=0';
          paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
          paramString += '&pageNumber=0';
          paramString += '&pageSize=999999';

          this.tableDataService
            .getTableData('entity_address/addresses', '?' + paramString)
            .subscribe((result: ResponseModel) => {
              // const data: any = result.Data;
              this.vm.shipToAddress = result.Data;
              if (this.vm.shipToAddress.length == '1') {
                this.vm.item.header.ship_address_id = this.vm.shipToAddress[0].id;
              }
            });
          // this.getContact();
          // this.getHistory();
        } else {
          this.notification.showErrorToast('The existing entity is no longer valid, please select another entity.  ');
          // error entity is no longer the correct receivable or payable
          this.vm.entityError = true;
          this.vm.item.header.entity_id = '';
        }
      }

      this.vm.item.header.header_date = this.datePipe.transform(
        new Date(this.vm.item.header.header_date),
        'MM/dd/yyyy'
      );
      // console.log(this.vm.item.header.header_date);
      for (let x = this.vm.item.detail.length - 1; x > -1; x--) {
        if (this.vm.item.detail[x].control_line == '1') {
          this.vm.item.detail.splice(x, 1);
        }
      }
      for (const [x, value] of Object.entries(this.vm.item.detail)) {
        this.vm.item.detail[x].quantity = Number(this.vm.item.detail[x].quantity);
        if (this.vm.item.detail[x].account_id == 'in') {
          this.vm.item.detail[x].accountInput = 'Inventory';
          this.vm.item.detail[x].disableAccount = true;
        }
        if (this.vm.item.detail[x].item_id) {
          if (this.FilterObject(this.vm.inventoryItem, { id: this.vm.item.detail[x].item_id }, true).length > 0) {
            this.vm.item.detail[x].itemInput = this.FilterObject(
              this.vm.inventoryItem,
              { id: this.vm.item.detail[x].item_id },
              true
            )[0].code;
          }
        }
        if (this.FilterObject(this.vm.account, { id: this.vm.item.detail[x].account_id }, true).length > 0) {
          this.vm.item.detail[x].accountInput =
            this.FilterObject(this.vm.account, { id: this.vm.item.detail[x].account_id }, true)[0].number +
            ' ' +
            this.FilterObject(this.vm.account, { id: this.vm.item.detail[x].account_id }, true)[0].name;
        }
      }
      for (let x = this.vm.item.detail.length - 1; x >= 0; --x) {
        if (this.vm.item.detail[x].quantity == 0) {
          this.vm.item.detail.splice(x, 1);
        }
      }
      this.calcTotal();
      this.setInitialFormValues();
      this.titleService.setTitle(this.vm.title);
    }

    this.buildEntityName();
    this.buildInventoryItemName();

    if (this.vm.subLedger == 'purchaseorders') {
      this.vm.email_message =
        'Hi [TONAME],\r\n \r\nPlease find attached purchase order number [NUMBER].\r\n \r\nThank you,\r\n[FROMNAME]';
    }
    // if (this.action === 'print') {
    //   this.tableDataService
    //     .getTableData('uom')
    //     .toPromise()
    //     .then((result: ResponseModel) => {
    //       const data: any = result.Data;
    //       this.print();
    //     });
    // }
    for (const [x, value] of Object.entries(this.vm.item.detail)) {
      if (this.vm.item.detail[x].amount !== undefined) {
        this.vm.item.detail[x].amount = this.changeToDecimal(this.vm.item.detail[x].amount);
      }
      if (this.vm.item.detail[x].quantity !== undefined) {
        this.vm.item.detail[x].quantity = this.changeToDecimal(this.vm.item.detail[x].quantity);
      }
    }
    // invoices
    // console.log('applied: ' + JSON.stringify(this.vm.item.applied));
    if (this.vm.item.applied) {
      this.vm.item.applied.forEach((x) => {
        if (x.number != '') {
          this.vm.buttonsDisabled = true;
        }
      });
    }
    if (
      (this.vm.item.header.payment_applied_total > 0 && this.vm.item.header.payment_applied_total == this.vm.total) ||
      this.vm.item.header.completed ||
      this.vm.item.batch.locked
    ) {
      // this.vm.paid = true; // disable form
      this.vm.buttonsDisabled = true; // disable buttons
      if (this.vm.subLedger == 'ar') {
        this.disablePrintButton = false;
        this.disableEmailButton = false;
      }
    }
    if (this.vm.item.header.payment_applied_total > 0 && this.vm.item.header.payment_applied_total == this.vm.total) {
      this.itemPaid = true; // form title
    }
    // sales order
    if (this.vm.item.batch.type_id == '10' && this.vm.item.header.completed) {
      // this.vm.paid = true; // disable form
      this.vm.buttonsDisabled = true; // disable buttons
      this.disablePrintButton = false;
      this.disableEmailButton = false;
    }
    if (this.vm.item.header.payment_applied_total > 0 && this.vm.item.header.payment_applied_total == this.vm.total) {
      this.itemPaid = true; // form title
    }

    this.checkForNewPoCreated();
    this.vm.isLoaded = true;

    if (!this.newDoc) {
      this.getHistory();
      this.getContact();
    }

    if (this.action === 'print') {
      this.printDocument();
     }
  }

  checkForNewPoCreated() {
    if (localStorage.getItem('itemsToOrder')) {
      this.vm.itemsToOrder = JSON.parse(localStorage.getItem('itemsToOrder'));
      this.vm.item.header.location_id = localStorage.getItem('itemsToOrderLocationId');
      this.vm.item.header.entityInput = localStorage.getItem('itemsToOrderEntityName');
      this.vm.item.header.entity_id = localStorage.getItem('itemsToOrderEntityId');
      this.vm.item.header.company_id = localStorage.getItem('itemsToOrderCompanyId');

      this.getAddresses();
      this.generateItemsToOrder();

      localStorage.removeItem('itemsToOrder');
      localStorage.removeItem('itemsToOrderLocationId');
      localStorage.removeItem('itemsToOrderEntityId');
      localStorage.removeItem('itemsToOrderCompanyId');
      localStorage.removeItem('itemsToOrderEntityName');
    }
  }

  generatedPOItems(event: any) {
    this.vm.itemsToOrder = JSON.parse(event);
    this.generateItemsToOrder();
  }

  setInitialFormValues() {
    if (!this.vm.paid && this.vm.item.detail) {
      this.addRows(3); // create initial detail row
    }
  }

  spliceEntities() {
    if (this.vm.subLedger) {
      if (this.vm.subLedger == 'ar' || this.vm.subLedger == 'quotes' || this.vm.subLedger == 'salesorders') {
        this.spliceReceivableEntities();
      } else {
        this.splicePayableEntities();
      }
    }
  }

  spliceReceivableEntities() {
    for (let y = this.vm.entity.length - 1; y > -1; y--) {
      if (this.vm.entity[y].receivable == '0') {
        if (this.vm.entity[y].id != this.vm.item.header.entity_id || !this.vm.item.header.entity_id || 1 == 1) {
          this.vm.entity.splice(y, 1);
        }
      }
    }
    this.buildEntityName();
  }

  /*
   * Entity Functions
   */

  splicePayableEntities() {
    for (let y = this.vm.entity.length - 1; y > -1; y--) {
      if (this.vm.entity[y].payable == '0') {
        if (this.vm.entity[y].id != this.vm.item.header.entity_id || !this.vm.item.header.entity_id || 1 == 1) {
          this.vm.entity.splice(y, 1);
        }
      }
    }
    this.buildEntityName();
  }

  buildEntityName() {
    this.vm.entityName = [];
    for (const [y, value] of Object.entries(this.vm.entity)) {
      const tempName = this.vm.entity[y].name;
      this.vm.entityName.push({
        name: tempName,
      });
    }
  }

  updateEntity() {
    this.vm.buttonsDisabled = true;
    this.vm.startEntityName = 0;

    this.tableDataService.getApiListData('entity', '?pageNumber=0&pageSize=999999').subscribe((result: any) => {
      this.vm.entity = result.Data;
      this.vm.buttonsDisabled = false;
      this.spliceEntities();
    });
  }

  updateItem() {
    this.tableDataService
      .getTableData('entry', this.documentId)
      .toPromise()
      .then((result: any) => {
        this.vm.item = result;
        this.init().then();
      });
  }

  selectEntityModal(entityName) {
    this.vm.item.header.entity_id = '';
    let paramString;
    for (const [y, value] of Object.entries(this.vm.entity)) {
      if (this.vm.entity[y].name == entityName) {
        this.vm.item.header.entityInput = entityName;
        this.vm.item.header.entity_id = this.vm.entity[y].id;

        paramString = '';
        paramString += 'billing_address=1';
        paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
        paramString += '&pageNumber=0';
        paramString += '&pageSize=999999';

        this.tableDataService
          .getTableData('entity_address/addresses', '?' + paramString)
          .subscribe((result: ResponseModel) => {
            // const data: any = result.Data;
            this.vm.billToAddress = result.Data;
            if (this.vm.billToAddress.length == 1) {
              this.vm.item.header.bill_address_id = this.vm.billToAddress[0].id;
            }
          });

        paramString = '';
        paramString += 'billing_address=0';
        paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
        paramString += '&pageNumber=0';
        paramString += '&pageSize=999999';

        this.tableDataService
          .getTableData('entity_address/addresses', '?' + paramString)
          .subscribe((result: ResponseModel) => {
            // const data: any = result.Data;
            this.vm.shipToAddress = result.Data;
            if (this.vm.shipToAddress.length == 1) {
              this.vm.item.header.ship_address_id = this.vm.shipToAddress[0].id;
            }
          });

        this.getHistory();
        // this.getEntityDefaults();
        // this.applyDefaultSettingsFromEntity();
        this.getAddresses();
        this.getContact();
        break;
      }
    }
  }

  getHistory() {
    if (this.vm.item.header.entity_id) {
      this.tableDataService.getTableData('entity_history', this.vm.item.header.entity_id).subscribe((result: any) => {
        this.vm.history = result;
        // console.log(JSON.stringify(this.vm.history));
        if (this.newDoc) {
          this.applyDefaultSettingsFromEntity();
        }
      });
    }
  }

  // refreshItemDefaultValues() {
  //   if (this.vm.item.header.entity_id) {
  //     this.tableDataService.getTableData('entity_history', this.vm.item.header.entity_id).subscribe((result: any) => {
  //       this.vm.history = result;
  //       console.log(JSON.stringify(result));
  //       // this.applyDefaultSettingsFromEntity();
  //     });
  //   }
  // }

  getAddresses() {
    let paramString = '';
    if (this.vm.item.header.entityInput) {
      for (const [y, value] of Object.entries(this.vm.entity)) {
        if (this.vm.entity[y].name == this.vm.item.header.entityInput) {
          this.vm.item.header.entity_id = this.vm.entity[y].id;

          paramString = '';
          paramString += 'billing_address=1';
          paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
          paramString += '&pageNumber=0';
          paramString += '&pageSize=999999';

          this.tableDataService
            .getTableData('entity_address/addresses', '?' + paramString)
            .subscribe((result: ResponseModel) => {
              // const data: any = result.Data;
              this.vm.billToAddress = result.Data;
              if (this.vm.billToAddress.length == 1) {
                this.vm.item.header.bill_address_id = this.vm.billToAddress[0].id;
              } else if (this.newDoc) {
                const primary = this.vm.billToAddress.filter((x) => x.primary_address);
                // catch when nothing set to primary
                if (primary.length > 0) {
                  this.vm.item.header.bill_address_id = primary[0].id;
                }
              }
            });

          paramString = '';
          paramString += 'billing_address=0';
          paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
          paramString += '&pageNumber=0';
          paramString += '&pageSize=999999';

          this.tableDataService
            .getTableData('entity_address/addresses', '?' + paramString)
            .subscribe((result: ResponseModel) => {
              // const data: any = result.Data;
              this.vm.shipToAddress = result.Data;
              if (this.vm.shipToAddress.length == 1) {
                this.vm.item.header.ship_address_id = this.vm.shipToAddress[0].id;
              }
            });

          paramString = '';
          paramString += 'entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
          paramString += '&pageNumber=0';
          paramString += '&pageSize=999999';

          this.tableDataService.getTableData('entity_contact', '?' + paramString).subscribe((result: ResponseModel) => {
            // const data: any = result.Data;
            this.vm.contact = result.Data;
            // if (this.vm.contact.length == 1) {
            //   this.vm.item.header.contact_id = this.vm.contact[0].id;
            // }
          });
          this.getContact();
          break;
        }
      }
    } else if (this.vm.item.header.entity_id) {
      paramString = '';
      paramString += 'billing_address=1';
      paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
      paramString += '&pageNumber=0';
      paramString += '&pageSize=999999';

      this.tableDataService
        .getTableData('entity_address/addresses', '?' + paramString)
        .subscribe((result: ResponseModel) => {
          // const data: any = result.Data;
          this.vm.billToAddress = result.Data;

          if (this.vm.billToAddress.length == 1) {
            this.vm.item.header.bill_address_id = this.vm.billToAddress[0].id;
          }
        });

      paramString = '';
      paramString += 'billing_address=0';
      paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
      paramString += '&pageNumber=0';
      paramString += '&pageSize=999999';

      this.tableDataService
        .getTableData('entity_address/addresses', '?' + paramString)
        .subscribe((result: ResponseModel) => {
          // const data: any = result.Data;
          this.vm.shipToAddress = result.Data;
          if (this.vm.shipToAddress.length == 1) {
            this.vm.item.header.ship_address_id = this.vm.shipToAddress[0].id;
          }
        });

      paramString = '';
      paramString += 'entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
      paramString += '&pageNumber=0';
      paramString += '&pageSize=999999';

      this.tableDataService.getTableData('entity_contact', '?' + paramString).subscribe((result: ResponseModel) => {
        // const data: any = result.Data;
        this.vm.contact = result.Data;
        // if (this.vm.contact.length == 1) {
        //   this.vm.item.header.contact_id = this.vm.contact[0].id;
        // }
      });
      this.getContact();
    }
  }

  getContact() {
    if (!this.vm.item.header.entity_id) {
      for (const [y, val] of Object.entries(this.vm.entity)) {
        if (this.vm.entity[y].name == this.vm.item.header.entityInput) {
          this.vm.item.header.entity_id = this.vm.entity[y].id;

          let paramString = '';
          paramString += 'entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
          paramString += '&pageNumber=0';
          paramString += '&pageSize=999999';

          this.tableDataService.getTableData('entity_contact', '?' + paramString).subscribe((result: ResponseModel) => {
            // const data: any = result.Data;
            this.vm.contact = result.Data;
          });
          break;
        }
      }
    } else {
      this.tableDataService
        .getTableData('entity_contact_list', this.vm.item.header.entity_id + '?pageNumber=0&pageSize=999999')
        .subscribe((result: ResponseModel) => {
          // const data: any = result.Data;
          this.vm.contact = result.Data;

          // if (this.vm.contact.length == 1) {
          //   this.vm.item.header.contact_id = this.vm.contact[0].id;
          // }
        });
    }
  }

  testEntity() {
    if (!this.vm.item.header.entityInput) {
      this.vm.entityError = true;
      // this.notification.showErrorToast('Entity is required.');
      return;
    } else {
      if (this.FilterObject(this.vm.entity, { name: this.vm.item.header.entityInput }, true).length > 0) {
        const entityId = this.FilterObject(this.vm.entity, { name: this.vm.item.header.entityInput }, true)[0].id;
        const entityName = this.FilterObject(this.vm.entity, { name: this.vm.item.header.entityInput }, true)[0].name;
        const entityEmail = this.FilterObject(this.vm.entity, { name: this.vm.item.header.entityInput }, true)[0].email;
        if (entityId != '') {
          this.vm.item.header.entity_id = entityId;
          this.vm.item.header.entityInput = entityName;
          this.vm.email.ToEmail = entityEmail;
        } else {
          this.vm.item.header.entity_id = '';
          this.vm.alert.status = 'error';
          this.notification.showWarningToast($localize`:Entity not found. @@entitynotfound:Entity not found.`);
          // this.vm.alert.message.push({
          //   text: $localize`:Entity not found. @@entitynotfound:Entity not found.`,
          // });
          this.vm.entityError = true;
        }
      } else {
        this.vm.item.header.entity_id = '';
        this.vm.email.ToEmail = '';
        this.vm.alert.status = 'error';
        this.notification.showWarningToast(
          $localize`:Entity @@entity:Entity` +
            ' ' +
            this.vm.item.header.entityInput +
            ' ' +
            $localize`:not found. @@notFound:not found.`
        );
        this.vm.entityError = true;
      }
    }
  }

  saveNewEntity() {
    let errorDescription = '';
    this.vm.entityButtonsDisabled = true;

    if (!this.vm.newEntity.name) {
      errorDescription += $localize`:Name is required. @@nameisrequired:Name is required.` + '<br/>';
      this.vm.newEntityNameError = true;
    } else if (this.FilterObject(this.vm.entity, { name: this.vm.newEntity.name }, true).length > 0) {
      if (this.FilterObject(this.vm.entity, { name: this.vm.newEntity.name }, true)[0].name == this.vm.newEntity.name) {
        this.vm.newEntityNameError = true;
        errorDescription += $localize`:Name is a duplicate. @@nameisaduplicate:Name is a duplicate.` + '<br/>';
      }
    }
    if (!this.vm.newEntity.type_id) {
      errorDescription += $localize`:Type is required. @@typeisrequired:Type is required.` + '<br/>';
      this.vm.newEntityTypeError = true;
    }
    if (!this.vm.newEntity.receivable && !this.vm.newEntity.payable) {
      this.vm.newEntityRPError = true;
      errorDescription +=
        $localize`:At least one of receivable or payable is required. @@atleastoneofreceivableorpayableisrequired:At least one of receivable or payable is required.` +
        '<br/>';
    }

    if (errorDescription === '' && (this.vm.permissions.Admin || this.vm.permissions.EditReceivableEntities)) {
      this.tableDataService.post('entity', this.vm.newEntity).subscribe(
        (item: any) => {
          // this.notification.success(item.description);
          this.vm.entityButtonsDisabled = false;
          this.updateEntity();
          // document.getElementById('EntityModelClose').click();
          this.vm.newEntity = {};
        },
        (error: { error: { Message: string }; statusText: string }) => {
          this.vm.entityButtonsDisabled = false;
          let ErrorMsg: string;
          if (typeof error.error.Message != 'undefined') {
            ErrorMsg = error.error.Message;
          } else {
            ErrorMsg = error.statusText;
          }
          // this.notification.error(ErrorMsg);
          console.log(ErrorMsg);
        }
      );
    } else {
      // report error
      this.vm.entityButtonsDisabled = false;
      // this.notification.error(errorDescription);
      console.log(errorDescription);
    }
  }

  saveNewAddress() {
    let errorDescription = '';
    this.vm.addressButtonsDisabled = true;
    this.vm.newAddress.entity_id = this.vm.item.header.entity_id;

    if (!this.vm.newAddress.name) {
      errorDescription += $localize`:Name is required. @@nameisrequired:Name is required.` + '<br/>';
      this.vm.newAddressNameError = true;
    } else if (this.vm.address) {
      if (
        this.FilterObject(this.vm.address, { name: this.vm.newAddress.name }, true)[0].name == this.vm.newAddress.name
      ) {
        this.vm.newAddressNameError = true;
        errorDescription += $localize`:Name is a duplicate. @@nameisaduplicate:Name is a duplicate.` + '<br/>';
      }
    }
    if (this.vm.newAddress.billing_address == '1') {
      this.vm.newAddress.billing_address = 1;
    } else if (this.vm.newAddress.billing_address == '0') {
      this.vm.newAddress.billing_address = 0;
    }
    if (errorDescription === '' && (this.vm.permissions.Admin || this.vm.permissions.EditReceivableEntities)) {
      this.tableDataService.post('entity_address', this.vm.newAddress).subscribe(
        (item: any) => {
          this.vm.addressButtonsDisabled = false;
          this.updateAddress();
          this.vm.newAddress = {};
        },
        (error: { error: { Message: string }; statusText: string }) => {
          this.vm.addressButtonsDisabled = false;
          let ErrorMsg: string;
          if (typeof error.error.Message != 'undefined') {
            ErrorMsg = error.error.Message;
          } else {
            ErrorMsg = error.statusText;
          }
          // this.notification.error(ErrorMsg);
          console.log(ErrorMsg);
        }
      );
    } else {
      // report error
      this.vm.addressButtonsDisabled = false;
      // this.notification.error(errorDescription);
      console.log(errorDescription);
    }
  }

  updateAddress() {
    let paramString = '';
    paramString += 'billing_address=1';
    paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
    paramString += '&pageNumber=0';
    paramString += '&pageSize=999999';

    this.tableDataService
      .getTableData('entity_address/addresses', '?' + paramString)
      .subscribe((result: ResponseModel) => {
        // const data: any = result.Data;
        this.vm.billToAddress = result.Data;
        if (this.vm.billToAddress.length == 1) {
          this.vm.item.header.bill_address_id = this.vm.billToAddress[0].id;
        }
      });

    paramString = '';
    paramString += 'billing_address=0';
    paramString += '&entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
    paramString += '&pageNumber=0';
    paramString += '&pageSize=999999';

    this.tableDataService
      .getTableData('entity_address/addresses', '?' + paramString)
      .subscribe((result: ResponseModel) => {
        // const data: any = result.Data;
        this.vm.shipToAddress = result.Data;
        if (this.vm.shipToAddress.length == 1) {
          this.vm.item.header.ship_address_id = this.vm.shipToAddress[0].id;
        }
      });
  }

  saveNewContact() {
    let errorDescription = '';
    this.vm.contactButtonsDisabled = true;
    this.vm.newContact.entity_id = this.vm.item.header.entity_id;

    if (!this.vm.newContact.name) {
      errorDescription += $localize`:Name is required. @@nameisrequired:Name is required.` + '<br/>';
      this.vm.newContactNameError = true;
    } else if (this.vm.contact) {
      if (this.FilterObject(this.vm.contact, { name: this.vm.newContact.name }, true).length > 0) {
        this.vm.newContactNameError = true;
        errorDescription += $localize`:Name is a duplicate. @@nameisaduplicate:Name is a duplicate.` + '<br/>';
      }
    }

    if (errorDescription === '' && (this.vm.permissions.Admin || this.vm.permissions.EditReceivableEntities)) {
      this.tableDataService.post('entity_contact', this.vm.newContact).subscribe(
        (item: any) => {
          // this.notification.success(item.description);
          this.vm.contactButtonsDisabled = false;
          this.updateContact();
          // document.getElementById('ContactNewClose').click();
          this.vm.newContact = {};
        },
        (error: { error: { Message: string }; statusText: string }) => {
          this.vm.contactButtonsDisabled = false;
          let ErrorMsg: string;
          if (typeof error.error.Message != 'undefined') {
            ErrorMsg = error.error.Message;
          } else {
            ErrorMsg = error.statusText;
          }
          // this.notification.error(ErrorMsg);
          console.log(ErrorMsg);
        }
      );
    } else {
      // report error
      this.vm.contactButtonsDisabled = false;
      // this.notification.error(errorDescription);
      console.log(errorDescription);
    }
  }

  updateContact() {
    let paramString = '';
    paramString += 'entity_id=' + (this.vm.item.header.entity_id ? this.vm.item.header.entity_id : '');
    paramString += '&pageNumber=0';
    paramString += '&pageSize=999999';

    this.tableDataService.getTableData('entity_contact', '?' + paramString).subscribe((result: ResponseModel) => {
      // const data: any = result.Data;
      this.vm.contact = result.Data;
    });
  }

  buildInventoryItemName() {
    this.vm.inventoryItemName = [];
    for (const [y, val] of Object.entries(this.vm.inventoryItem)) {
      let tempUOM = '';
      // if ((this.FilterObject(this.vm.uom, { id: this.vm.inventoryItem[y].uom_id }, true))) {
      //    tempUOM = this.FilterObject(this.vm.uom, { id: this.vm.inventoryItem[y].uom_id }, true)[0].name;
      // }
      if (this.FilterObject(this.vm.uom, { id: this.vm.inventoryItem[y].uom_id }, true).length > 0) {
        tempUOM = this.FilterObject(this.vm.uom, { id: this.vm.inventoryItem[y].uom_id }, true)[0].name;
      }
      const tempName = this.vm.inventoryItem[y].code + ' ' + this.vm.inventoryItem[y].name + ' ' + tempUOM;
      // var tempName = this.vm.inventoryItem[y].name + ' ' + tempUOM;
      this.vm.inventoryItemName.push({
        name: tempName,
      });
    }
  }

  updateInventoryItem() {
    this.vm.startInventoryItem = 0;

    this.tableDataService.getTableData('item', '?pageNumber=0&pageSize=999999').subscribe((result: ResponseModel) => {
      // const data: any = result.Data;
      this.vm.inventoryItem = result.Data;
    });
    this.buildInventoryItemName();
  }

  /*
   * Inventory Functions
   */

  selectItemDropdown(id) {
    let foundItem = false;
    let maxSortOrder = 0;
    for (const [x, val] of Object.entries(this.vm.item.detail)) {
      if (maxSortOrder < this.vm.item.detail[x].sort_order) {
        maxSortOrder = this.vm.item.detail[x].sort_order;
      }
    }
    for (const [x, value] of Object.entries(this.vm.item.detail)) {
      this.vm.item.detail[x].disableAccount = false;
      if (this.vm.item.detail[x].id == id && this.vm.item.detail[x].itemInput) {
        this.vm.item.detail[x].item_id = '';
        for (const [y, val] of Object.entries(this.vm.inventoryItem)) {
          let tempUOM = '';
          if (this.FilterObject(this.vm.uom, { id: this.vm.inventoryItem[y].uom_id }, true).length > 0) {
            tempUOM = this.FilterObject(this.vm.uom, { id: this.vm.inventoryItem[y].uom_id }, true)[0].name;
          }
          if (
            this.vm.inventoryItem[y].code + ' ' + this.vm.inventoryItem[y].name + ' ' + tempUOM ==
              this.vm.item.detail[x].itemInput ||
            this.vm.inventoryItem[y].code == this.vm.item.detail[x].itemInput
          ) {
            this.vm.item.detail[x].header_id = this.vm.item.header.id;
            this.vm.item.detail[x].description = this.vm.inventoryItem[y].name;
            this.vm.item.detail[x].itemInput = this.vm.inventoryItem[y].code;
            this.vm.item.detail[x].item_id = this.vm.inventoryItem[y].id;
            this.vm.item.detail[x].uom_id = this.vm.inventoryItem[y].uom_id;
            if (this.vm.subLedger == 'ar' || this.vm.subLedger == 'salesorders' || this.vm.subLedger == 'quotes') {
              this.vm.item.detail[x].amount = Number(this.vm.inventoryItem[y].price);
              if (this.vm.history.Pricing) {
                if (
                  this.vm.history.Pricing.length > 0 &&
                  (this.vm.subLedger == 'ar' || this.vm.subLedger == 'quotes' || this.vm.subLedger == 'salesorders')
                ) {
                  for (const [z, valu] of Object.entries(this.vm.history.Pricing)) {
                    if (this.vm.history.Pricing[z].item_id == this.vm.item.detail[x].item_id) {
                      this.vm.item.detail[x].amount = Number(this.vm.history.Pricing[z].price);
                      break;
                    }
                  }
                }
              }
              if (this.vm.inventoryItem[y].receivable_tax_1_id !== undefined) {
                this.vm.item.detail[x].tax_id_1 = this.vm.inventoryItem[y].receivable_tax_1_id;
              }
              if (this.vm.inventoryItem[y].receivable_tax_2_id !== undefined) {
                this.vm.item.detail[x].tax_id_2 = this.vm.inventoryItem[y].receivable_tax_2_id;
              }
            } else {
              this.vm.item.detail[x].amount = Number(this.vm.inventoryItem[y].cost);
              if (this.vm.inventoryItem[y].payable_tax_1_id !== undefined) {
                this.vm.item.detail[x].tax_id_1 = this.vm.inventoryItem[y].payable_tax_1_id;
              }
              if (this.vm.inventoryItem[y].payable_tax_2_id !== undefined) {
                this.vm.item.detail[x].tax_id_2 = this.vm.inventoryItem[y].payable_tax_2_id;
              }
            }
            maxSortOrder++;
            // this.vm.item.detail[x].sort_order = maxSortOrder;
            let tempAccountId = '';
            let tempAccountInput = '';
            let disableAccount = false;
            if (
              (this.vm.subLedger == 'ap' || this.vm.subLedger == 'purchaseorders') &&
              this.vm.inventoryItem[y].inventory_account_id
            ) {
              tempAccountId = this.vm.inventoryItem[y].inventory_account_id;
              tempAccountInput = 'Inventory';
              disableAccount = true;
            } else if (
              (this.vm.subLedger == 'ap' || this.vm.subLedger == 'purchaseorders') &&
              this.vm.inventoryItem[y].expense_account_id
            ) {
              tempAccountId = this.vm.inventoryItem[y].expense_account_id;
            } else if (
              (this.vm.subLedger == 'ar' || this.vm.subLedger == 'salesorders' || this.vm.subLedger == 'quotes') &&
              this.vm.inventoryItem[y].revenue_account_id
            ) {
              tempAccountId = this.vm.inventoryItem[y].revenue_account_id;
            }
            if (tempAccountId && !tempAccountInput) {
              // ?????
              // disableAccount = true;
              tempAccountInput = this.FilterObject(this.vm.account, { id: tempAccountId }, true)[0].number;
              tempAccountInput += ' ' + this.FilterObject(this.vm.account, { id: tempAccountId }, true)[0].name;
            }
            this.vm.item.detail[x].disableAccount = disableAccount;
            this.vm.item.detail[x].account_id = tempAccountId;
            this.vm.item.detail[x].accountInput = tempAccountInput;
            this.calcTax(this.vm.item.detail[x].id).then();
            foundItem = true;
            break;
          }
        }
        if (!foundItem) {
          this.vm.item.detail[x].itemInput = '';
          // this.notification.error('Invalid Item, please try again.');
        }
        break;
      }
    }
  }

  selectItemModal(itemId) {
    const id = this.vm.tempItemDetailId;
    let maxSortOrder = 0;
    for (const [x, value] of Object.entries(this.vm.item.detail)) {
      if (maxSortOrder < this.vm.item.detail[x].sort_order) {
        maxSortOrder = this.vm.item.detail[x].sort_order;
      }
    }
    for (const [x, values] of Object.entries(this.vm.item.detail)) {
      if (this.vm.item.detail[x].id == id) {
        this.vm.item.detail[x].item_id = '';
        for (const [y, val] of Object.entries(this.vm.inventoryItem)) {
          if (this.vm.inventoryItem[y].id == itemId) {
            this.vm.item.detail[x].header_id = this.vm.item.header.id;
            this.vm.item.detail[x].description = this.vm.inventoryItem[y].name;
            this.vm.item.detail[x].itemInput = this.vm.inventoryItem[y].code;
            this.vm.item.detail[x].item_id = this.vm.inventoryItem[y].id;
            if (this.vm.item.detail[x].quantity === '' || this.vm.item.detail[x].quantity == 'undefined') {
              this.vm.item.detail[x].quantity = 1;
            }
            this.vm.item.detail[x].uom_id = this.vm.inventoryItem[y].uom_id;
            if (this.vm.subLedger === 'ar' || this.vm.subLedger === 'quotes' || this.vm.subLedger === 'salesorders') {
              this.vm.item.detail[x].amount = Number(this.vm.inventoryItem[y].price);
              if (this.vm.history.Pricing) {
                if (
                  this.vm.history.Pricing.length > 0 &&
                  (this.vm.subLedger === 'ar' || this.vm.subLedger === 'quotes' || this.vm.subLedger === 'salesorders')
                ) {
                  for (const [z, value] of Object.entries(this.vm.history.Pricing)) {
                    if (this.vm.history.Pricing[z].item_id == this.vm.item.detail[x].item_id) {
                      this.vm.item.detail[x].amount = Number(this.vm.history.Pricing[z].price);
                      break;
                    }
                  }
                }
              }
              if (this.vm.inventoryItem[y].receivable_tax_1_id !== undefined) {
                this.vm.item.detail[x].tax_id_1 = this.vm.inventoryItem[y].receivable_tax_1_id;
              }
              if (this.vm.inventoryItem[y].receivable_tax_2_id !== undefined) {
                this.vm.item.detail[x].tax_id_2 = this.vm.inventoryItem[y].receivable_tax_2_id;
              }
            } else {
              this.vm.item.detail[x].amount = Number(this.vm.inventoryItem[y].cost);
              if (this.vm.inventoryItem[y].payable_tax_1_id !== undefined) {
                this.vm.item.detail[x].tax_id_1 = this.vm.inventoryItem[y].payable_tax_1_id;
              }
              if (this.vm.inventoryItem[y].payable_tax_2_id !== undefined) {
                this.vm.item.detail[x].tax_id_2 = this.vm.inventoryItem[y].payable_tax_2_id;
              }
            }
            maxSortOrder++;
            let tempAccountId = '';
            let tempAccountInput = '';
            let disableAccount = false;
            if (
              (this.vm.subLedger === 'ap' || this.vm.subLedger === 'purchaseorders') &&
              this.vm.inventoryItem[y].inventory_account_id
            ) {
              tempAccountId = this.vm.inventoryItem[y].inventory_account_id;
              tempAccountInput = 'Inventory';
              disableAccount = true;
            } else if (
              (this.vm.subLedger === 'ap' || this.vm.subLedger === 'purchaseorders') &&
              this.vm.inventoryItem[y].expense_account_id
            ) {
              tempAccountId = this.vm.inventoryItem[y].expense_account_id;
            } else if (
              (this.vm.subLedger === 'ar' || this.vm.subLedger === 'salesorders' || this.vm.subLedger === 'quotes') &&
              this.vm.inventoryItem[y].revenue_account_id
            ) {
              tempAccountId = this.vm.inventoryItem[y].revenue_account_id;
            }
            if (tempAccountId && !tempAccountInput) {
              // ??????
              // disableAccount = true;
              tempAccountInput = this.FilterObject(this.vm.account, { id: tempAccountId }, true)[0].number;
              tempAccountInput += ' ' + this.FilterObject(this.vm.account, { id: tempAccountId }, true)[0].name;
            }
            this.vm.item.detail[x].disableAccount = disableAccount;
            this.vm.item.detail[x].account_id = tempAccountId;
            this.vm.item.detail[x].accountInput = tempAccountInput;
            this.calcTax(this.vm.item.detail[x].id).then();
            // this.vm.item.detail = this.OrderBy(this.vm.item.detail, 'sort_order' );
            break;
          }
        }
        break;
      }
    }
  }

  insertItemModal(itemId) {
    let maxSortOrder = 0;
    for (const [x, val] of Object.entries(this.vm.item.detail)) {
      if (maxSortOrder < this.vm.item.detail[x].sort_order) {
        maxSortOrder = this.vm.item.detail[x].sort_order;
      }
    }
    for (let y = 0; y < this.vm.inventoryItem.length; y++) {
      if (this.vm.inventoryItem[y].id == itemId) {
        maxSortOrder++;
        const tempDate = new Date();
        const tempID =
          tempDate.getMinutes() * (tempDate.getSeconds() + tempDate.getMilliseconds()) + this.vm.item.detail.length;
        let qty = 1;
        if (this.vm.inventoryItemInsertQty != '') {
          qty = this.vm.inventoryItemInsertQty;
        }

        const tempuomId = this.vm.inventoryItem[y].uom_id;
        let temptaxId1 = '';
        let temptaxId2 = '';
        if (this.vm.subLedger === 'ar') {
          if (this.vm.inventoryItem[y].receivable_tax_1_id !== undefined) {
            temptaxId1 = this.vm.inventoryItem[y].receivable_tax_1_id;
          }
          if (this.vm.inventoryItem[y].receivable_tax_2_id !== undefined) {
            temptaxId2 = this.vm.inventoryItem[y].receivable_tax_2_id;
          }
        } else {
          if (this.vm.inventoryItem[y].payable_tax_1_id !== undefined) {
            temptaxId1 = this.vm.inventoryItem[y].payable_tax_1_id;
          }
          if (this.vm.inventoryItem[y].payable_tax_2_id !== undefined) {
            temptaxId2 = this.vm.inventoryItem[y].payable_tax_2_id;
          }
        }
        let tempAccountId = '';
        let tempAccountInput = '';
        let tempDisableAccount = false;
        let tempAmount = 0;
        if (
          (this.vm.subLedger === 'ap' || this.vm.subLedger === 'purchaseorders') &&
          this.vm.inventoryItem[y].inventory_account_id
        ) {
          tempAmount = Number(this.vm.inventoryItem[y].cost);
          tempAccountId = this.vm.inventoryItem[y].inventory_account_id;
          tempAccountInput = 'Inventory';
          tempDisableAccount = true;
        } else if (
          (this.vm.subLedger === 'ap' || this.vm.subLedger === 'purchaseorders') &&
          this.vm.inventoryItem[y].expense_account_id
        ) {
          tempAmount = Number(this.vm.inventoryItem[y].cost);
          tempAccountId = this.vm.inventoryItem[y].expense_account_id;
        } else if (
          (this.vm.subLedger === 'ar' || this.vm.subLedger === 'quotes' || this.vm.subLedger === 'salesorders') &&
          this.vm.inventoryItem[y].revenue_account_id
        ) {
          tempAmount = Number(this.vm.inventoryItem[y].price);
          if (this.vm.history.Pricing) {
            if (
              this.vm.history.Pricing.length > 0 &&
              (this.vm.subLedger === 'ar' || this.vm.subLedger === 'quotes' || this.vm.subLedger == 'salesorders')
            ) {
              for (const [x, value] of Object.entries(this.vm.history.Pricing)) {
                if (this.vm.item.detail[x].item_id) {
                  if (this.vm.history.Pricing[y].item_id == this.vm.item.detail[x].item_id) {
                    tempAmount = Number(this.vm.history.Pricing[y].price);
                    break;
                  }
                }
              }
            }
          }
          tempAccountId = this.vm.inventoryItem[y].revenue_account_id;
        }
        if (tempAccountId && !tempAccountInput) {
          // tempDisableAccount = true;
          tempAccountInput = this.FilterObject(this.vm.account, { id: tempAccountId }, true)[0].number;
          tempAccountInput += ' ' + this.FilterObject(this.vm.account, { id: tempAccountId }, true)[0].name;
        }

        this.vm.item.detail.push({
          id: tempID,
          header_id: this.vm.item.header.id,
          item_id: this.vm.inventoryItem[y].id,
          itemInput: this.vm.inventoryItem[y].code,
          description: this.vm.inventoryItem[y].name,
          quantity: Number(qty),
          amount: tempAmount,
          uom_id: this.vm.inventoryItem[y].uom_id,
          account_id: tempAccountId,
          accountInput: tempAccountInput,
          disableAccount: tempDisableAccount,
          sort_order: maxSortOrder,
          tax_id_1: temptaxId1,
          tax_id_2: temptaxId2,
        });
        this.calcTax(tempID).then();
        // this.vm.item.detail = this.OrderBy(this.vm.item.detail, 'sort_order' );
        break;
      }
    }
  }

  saveNewItem() {
    let errorDescription = '';
    this.vm.itemButtonsDisabled = true;

    if (!this.vm.newItem.name) {
      errorDescription += $localize`:Name is required. @@nameisrequired:Name is required.` + '<br/>';
      this.vm.newItemNameError = true;
    } else if (this.FilterObject(this.vm.inventoryItem, { name: this.vm.newItem.name }, true).length > 0) {
      if (
        this.FilterObject(this.vm.inventoryItem, { name: this.vm.newItem.name }, true)[0].name == this.vm.newItem.name
      ) {
        this.vm.newItemNameError = true;
        errorDescription += $localize`:Name is a duplicate. @@nameisaduplicate:Name is a duplicate.` + '<br/>';
      }
    }
    if (!this.vm.newItem.code) {
      errorDescription += $localize`:Code is required. @@codeisrequired:Code is required.` + '<br/>';
      this.vm.newItemCodeError = true;
    } else if (this.FilterObject(this.vm.inventoryItem, { name: this.vm.newItem.code }, true).length > 0) {
      if (
        this.FilterObject(this.vm.inventoryItem, { name: this.vm.newItem.code }, true)[0].code == this.vm.newItem.code
      ) {
        this.vm.newItemCodeError = true;
        errorDescription += $localize`:Code is a duplicate. @@codeisaduplicate:Code is a duplicate.` + '<br/>';
      }
    }
    if (!this.vm.newItem.price) {
      errorDescription += $localize`:Price is required. @@priceisrequired:Price is required.` + '<br/>';
      this.vm.newItemPriceError = true;
    }

    if (errorDescription === '' && (this.vm.permissions.Admin || this.vm.permissions.EditItems)) {
      this.tableDataService.post('item', this.vm.newItem).subscribe(
        (item: any) => {
          // this.notification.success(item.description);
          this.vm.itemButtonsDisabled = false;
          this.updateInventoryItem();
          // document.getElementById('ItemNewClose').click();
          this.vm.newItem = {};
        },
        (error: { error: { Message: string }; statusText: string }) => {
          this.vm.itemButtonsDisabled = false;
          let ErrorMsg: string;
          if (typeof error.error.Message != 'undefined') {
            ErrorMsg = error.error.Message;
          } else {
            ErrorMsg = error.statusText;
          }
          // this.notification.error(ErrorMsg);
          console.log(ErrorMsg);
        }
      );
    } else {
      // report error
      this.vm.itemButtonsDisabled = false;
      // this.notification.error(errorDescription);
      console.log(errorDescription);
    }
  }

  updateAccount() {
    this.vm.startAccount = 0;
    this.tableDataService
      .getTableData('account', '?pageNumber=0&pageSize=999999')
      .subscribe((result: ResponseModel) => {
        this.vm.account = result.Data;
      });
  }

  selectAccountModal(accountId) {
    const id = this.vm.tempAccountDetailId;
    for (const [x, value] of Object.entries(this.vm.item.detail)) {
      if (this.vm.item.detail[x].id == id) {
        for (const [y, val] of Object.entries(this.vm.account)) {
          if (this.vm.account[y].id == accountId) {
            this.vm.item.detail[x].account_id = this.vm.account[y].id;
            this.vm.item.detail[x].accountInput = this.vm.account[y].number + ' ' + this.vm.account[y].name;
            break;
          }
        }
        break;
      }
    }
  }

  //  ACCOUNTS

  accountSelected(id) {
    for (const [x, value] of Object.entries(this.vm.item.detail)) {
      if (this.vm.item.detail[x].id == id) {
        this.vm.item.detail[x].account_id = '';
        for (const [y, val] of Object.entries(this.vm.account)) {
          if (this.vm.account[y].number + ' ' + this.vm.account[y].name == this.vm.item.detail[x].accountInput) {
            this.vm.item.detail[x].account_id = this.vm.account[y].id;
            break;
          }
        }
        break;
      }
    }
  }

  saveNewAccount() {
    let errorDescription = '';
    this.vm.accountButtonsDisabled = true;

    if (!this.vm.newAccount.name) {
      errorDescription += $localize`:Name is required. @@nameisrequired:Name is required.` + '<br/>';
      this.vm.newAccountNameError = true;
    } else if (this.FilterObject(this.vm.account, { name: this.vm.newAccount.name }, true).length > 0) {
      if (
        this.FilterObject(this.vm.account, { name: this.vm.newAccount.name }, true)[0].name == this.vm.newAccount.name
      ) {
        this.vm.newAccountNameError = true;
        errorDescription += $localize`:Name is a duplicate. @@nameisaduplicate:Name is a duplicate.` + '<br/>';
      }
    }
    if (!this.vm.newAccount.number) {
      errorDescription += $localize`:Number is required. @@numberisrequired:Number is required.` + '<br/>';
      this.vm.newAccountNumberError = true;
    } else if (this.FilterObject(this.vm.account, { name: this.vm.newAccount.number }, true).length > 0) {
      if (
        this.FilterObject(this.vm.account, { name: this.vm.newAccount.number }, true)[0].number ==
        this.vm.newAccount.number
      ) {
        this.vm.newAccountNumberError = true;
        errorDescription += $localize`:Number is a duplicate. @@numberisaduplicate:Number is a duplicate.` + '<br/>';
      }
    }
    if (!this.vm.newAccount.subtotal_id) {
      errorDescription += $localize`:Subtotal is required. @@subtotalisrequired:Subtotal is required.` + '<br/>';
      this.vm.newAccountSubtotalError = true;
    }

    if (errorDescription === '' && this.vm.permissions.Admin) {
      this.tableDataService.post('account', this.vm.newAccount).subscribe(
        (item: any) => {
          // this.notification.success(item.description);
          this.vm.accountButtonsDisabled = false;
          this.updateAccount();
          // document.getElementById('AccountNewClose').click();
          this.vm.newAccount = {};
        },
        (error: { error: { Message: string }; statusText: string }) => {
          this.vm.accountButtonsDisabled = false;
          let ErrorMsg: string;
          if (typeof error.error.Message != 'undefined') {
            ErrorMsg = error.error.Message;
          } else {
            ErrorMsg = error.statusText;
          }
          // this.notification.error(ErrorMsg);
          console.log(ErrorMsg);
        }
      );
    } else {
      // report error
      this.vm.accountButtonsDisabled = false;
      // this.notification.error(errorDescription);
      console.log(errorDescription);
    }
  }

  addSalesperson(id) {
    for (const [x, value] of Object.entries(this.vm.salesperson)) {
      if (this.vm.salesperson[x].id == id) {
        let errorDescription = '';
        for (const [y, val] of Object.entries(this.vm.salespeople)) {
          if (this.vm.item.salespeople[y].name == this.vm.salesperson[x].name) {
            // error
            errorDescription = $localize`:Duplicate Salesperson @@duplicateSalesperson:Duplicate Salesperson`;
            break;
          }
        }

        if (errorDescription === '') {
          const tempDate = new Date();
          const tempID =
            tempDate.getMinutes() * (tempDate.getSeconds() + tempDate.getMilliseconds()) +
            this.vm.item.salespeople.length;
          this.vm.item.salespeople.push({
            id: tempID,
            salesperson_id: this.vm.salesperson[x].id,
            name: this.vm.salesperson[x].name,
            rate: this.vm.salesperson[x].rate,
          });
          this.notification.showSuccessToast(this.vm.salesperson[x].name + ' added!');
        } else {
          this.notification.showErrorToast(errorDescription);
        }
        break;
      }
    }
  }

  changeCompany() {
    this.isCompanySelected = this.vm.item.header.company_id != '';
    if (this.vm.item.header.company_id) {
      // reset to all banks
      const banks = Object.assign({}, this.vm.allBanks);
      this.vm.bank = Object.values(banks);
      for (let x = this.vm.bank.length - 1; x >= 0; x--) {
        if (this.vm.bank[x].company_id != this.vm.item.header.company_id) {
          this.vm.bank.splice(x, 1);
        }
      }
      if (this.vm.bank.length == 1) {
        this.vm.payment.header.bank_id = this.vm.bank[0].id;
      } else {
        this.vm.payment.header.bank_id = '';
      }
    }
  }

  //  SALESPERSON

  addRows(qty) {
    if (qty == '') {
      qty = 0;
    }
    let maxSortOrder = 0;
    for (const [x, value] of Object.entries(this.vm.item.detail)) {
      if (maxSortOrder < this.vm.item.detail[x].sort_order) {
        maxSortOrder = this.vm.item.detail[x].sort_order;
      }
    }
    for (let x = 0; x < qty; x++) {
      maxSortOrder++;
      // tempID = this.vm.item.detail.length + 1;
      // tempDate = new Date();
      // tempID = tempDate.getMinutes() * (tempDate.getSeconds() + tempDate.getMilliseconds()) + this.vm.item.detail.length;
      this.vm.item.detail.push({
        id: maxSortOrder,
        sort_order: maxSortOrder,
        quantity: 0,
        debit_credit: NaN,
      });
    }
  }

  // UTILITIES

  deleteLine(id) {
    for (let x = 0; x < this.vm.item.detail.length; x++) {
      if (this.vm.item.detail[x].id == id) {
        this.vm.item.detail.splice(x, 1);
        this.calcTotal();
        break;
      }
    }
  }

  changeToDecimal(value) {
    value = parseFloat(value);
    return value.toFixed(2);
  }

  calcTotal() {
    // console.log('calc run');
    let subtotal = 0.0;
    let taxtotal = 0.0;

    let taxAmount1 = 0.0;
    let taxAmount2 = 0.0;

    this.vm.invoiceTaxes = [];

    for (const [x, value] of Object.entries(this.vm.item.detail)) {
      if (this.vm.item.detail[x].amount && this.vm.item.detail[x].quantity) {
        if (this.vm.item.detail[x].amount !== '0' && this.vm.item.detail[x].quantity === '0.00') {
          this.vm.item.detail[x].quantity = '1.00';
        }

        if (
          !Number(this.vm.item.detail[x].amount) &&
          this.vm.item.detail[x].amount != '-' &&
          this.vm.item.detail[x].amount != '0' &&
          this.vm.item.detail[x].amount != '0.00'
        ) {
          this.notification.showErrorToast(
            $localize`:Amount @@amount:Amount` +
              '" <strong>' +
              this.vm.item.detail[x].amount +
              '</strong>" ' +
              $localize`:is not a number, please remove any text. @@isnotanumberpleaseremoveanytext:is not a number, please remove any text.` +
              '<br/>'
          );
        } else if (!Number(this.vm.item.detail[x].quantity)) {
          this.notification.showErrorToast(
            $localize`:Quantity @@quantity:Quantity` +
              '" <strong>' +
              this.vm.item.detail[x].quantity +
              '</strong>" ' +
              $localize`:is not a number, please remove any text. @@isnotanumberpleaseremoveanytext:is not a number, please remove any text.`
          );
        } else {
          subtotal += Math.round(this.vm.item.detail[x].amount * this.vm.item.detail[x].quantity * 100) / 100;

          if (this.vm.account.length > 0 && this.vm.item.detail.length > 0) {
            if (
              !this.vm.item.detail[x].item_id &&
              (this.vm.item.detail[x].account_id !== undefined || this.vm.item.detail[x].account_id !== '') &&
              this.vm.history
            ) {
              if (this.vm.history.Defaults.account_id !== undefined && this.vm.history.Defaults.account_id) {
                this.vm.item.detail[x].account_id = this.vm.history.Defaults.account_id;
                this.vm.item.detail[x].accountInput =
                  this.FilterObject(this.vm.account, { id: this.vm.item.detail[x].account_id }, true)[0].number +
                  ' ' +
                  this.FilterObject(this.vm.account, { id: this.vm.item.detail[x].account_id }, true)[0].name;
              }
            }
          }
          // console.log('after filtering account');
          if (this.vm.item.detail[x].tax_id_1) {
            taxAmount1 = Number(this.vm.item.detail[x].tax_amount_1);
            let foundCode = false;

            // console.log('before filtering tax');
            if (this.vm.tax.length > 0) {
              const taxCode = this.FilterObject(this.vm.tax, {
                id: this.vm.item.detail[x].tax_id_1,
              })[0].code;

              for (const [z, val] of Object.entries(this.vm.invoiceTaxes)) {
                if (this.vm.invoiceTaxes[z].taxCode == taxCode) {
                  // console.log('found tax code: ' + taxCode);
                  foundCode = true;
                  this.vm.invoiceTaxes[z].taxAmount += taxAmount1;
                  break;
                }
              }

              if (!foundCode) {
                // console.log('NOT found tax code: ' + taxCode);
                this.vm.invoiceTaxes.push({
                  taxCode,
                  taxAmount: taxAmount1,
                });
              }
            }
            // console.log('after filtering tax 1: ' + taxtotal);
            // console.log('after filtering tax 1 - invoiceTaxes array: ' + JSON.stringify(this.vm.invoiceTaxes));
          }

          if (this.vm.item.detail[x].tax_id_2) {
            taxAmount2 = Number(this.vm.item.detail[x].tax_amount_2);
            let foundCode = false;

            // console.log('before filtering tax');
            if (this.vm.tax.length > 0) {
              const taxCode = this.FilterObject(this.vm.tax, {
                id: this.vm.item.detail[x].tax_id_2,
              })[0].code;

              for (const [z, val] of Object.entries(this.vm.invoiceTaxes)) {
                if (this.vm.invoiceTaxes[z].taxCode == taxCode) {
                  foundCode = true;
                  this.vm.invoiceTaxes[z].taxAmount += taxAmount2;
                  break;
                }
              }

              if (!foundCode) {
                this.vm.invoiceTaxes.push({
                  taxCode,
                  taxAmount: taxAmount2,
                });
              }
            }
            // console.log('after filtering tax2');
          }

          taxtotal += taxAmount1 + taxAmount2;
          // console.log('taxtotal filtering tax: ' + taxtotal );
          // console.log('after filtering tax1: ' + taxAmount1 );
          // console.log('after filtering tax2: ' + taxAmount2 );

          if (taxtotal > 0) {
            this.vm.showTaxes = true;
          }
          // console.log('tax recs: ' + JSON.stringify(this.vm.invoiceTaxes));
        }
      }
    }
    this.vm.taxtotal = taxtotal;
    this.vm.subtotal = subtotal;
    this.vm.total = Number(this.vm.subtotal) + Number(this.vm.taxtotal);
    // return total;
  }

  async calcTax(id) {
    // console.log('calc tax run: for:' + id);
    // console.log('looks for match in id in this array: ' + JSON.stringify(this.vm.item.detail));
    // console.log('uses this as the base it tries to match from: ' + JSON.stringify(this.vm.history));
    // id = id.split(' ')[1];
    let taxRate = 0;

    // let lee = this.taxEngine.getTaxRate(id = id.split(' ')[1], this.vm.item.header_date, this.vm.item.header.entity_id, 'R');
    // console.log('return from method: ' + JSON.stringify(lee));

    let taxTotal;
    let taxAmount1;
    let taxAmount2;
    // let taxRate = 0;
    let taxCode = [];

    for (const [x, value] of Object.entries(this.vm.item.detail)) {
      if (this.vm.item.detail[x].id === id) {
        taxAmount1 = 0;
        taxAmount2 = 0;
        taxRate = 0;

        // tax 1
        if (this.vm.item.detail[x].tax_id_1) {
          this.vm.item.detail[x].taxCode_1 = taxCode = this.vm.tax.filter(
            (t) => t.id === this.vm.item.detail[x].tax_id_1
          );
          // ???
          this.vm.item.detail[x].taxCode_1 = taxCode = this.FilterObject(
            this.vm.tax,
            { id: this.vm.item.detail[x].tax_id_1 },
            true
          )[0].code;
          let taxOverride = false;
          if (this.vm.subLedger === 'ar' && this.vm.history) {
            if (this.vm.history.Taxes.receivable_tax_1_id == null) {
            } else if (this.vm.history.Taxes.receivable_tax_2_id == null) {
              if (this.vm.history.Taxes.receivable_tax_1_id == this.vm.item.detail[x].tax_id_1) {
                // console.log('here 1');
                taxRate = this.vm.history.Taxes.receivable_tax_1_rate;
                taxOverride = true;
              }
            } else if (this.vm.history.Taxes.receivable_tax_2_id == null) {
              if (this.vm.history.Taxes.receivable_tax_2_id == this.vm.item.detail[x].tax_id_1) {
                // console.log('here 2');
                taxRate = this.vm.history.Taxes.receivable_tax_2_rate;
                taxOverride = true;
              }
            }
          } else if (this.vm.history) {
            if (this.vm.history.Taxes.payable_tax_1_id == null) {
              if (this.vm.history.Taxes.payable_tax_1_id == this.vm.item.detail[x].tax_id_1) {
                taxRate = this.vm.history.Taxes.payable_tax_1_rate;
                taxOverride = true;
              }
            } else if (this.vm.history.Taxes.payable_tax_2_id == null) {
              if (this.vm.history.Taxes.payable_tax_2_id == this.vm.item.detail[x].tax_id_1) {
                taxRate = this.vm.history.Taxes.payable_tax_2_rate;
                taxOverride = true;
              }
            }
          }
          if (taxRate >= 1) {
            taxRate = taxRate / 100;
          }
          let rateFromDate;
          let headerDate;
          let rateToDate;
          if (!taxOverride) {
            // console.log('in override');
            for (const [y, values] of Object.entries(this.vm.taxRate)) {
              if (this.vm.item.detail[x].tax_id_1 == this.vm.taxRate[y].tax_id) {
                if (this.vm.taxRate[y].from_date) {
                  rateFromDate = new Date(this.vm.taxRate[y].from_date);
                } else {
                  rateFromDate = new Date('2000-01-01');
                }
                if (this.vm.taxRate[y].to_date) {
                  rateToDate = new Date(this.vm.taxRate[y].to_date);
                } else {
                  rateToDate = new Date('2100-12-31');
                }

                if (this.vm.item.header.header_date) {
                  headerDate = new Date(this.vm.item.header.header_date);
                } else {
                  headerDate = new Date();
                  this.notification.showErrorToast(
                    $localize`:No date selected. @@nodateselected:No date selected.` +
                      '<br/>' +
                      $localize`:Tax rates calculated using today\'s date. @@taxRatesCalculatedUsingTodaysDate:Tax rates calculated using today\'s date.`
                  );
                  this.vm.dateMissing = true;
                }

                if (!this.vm.taxRate[y].from_date && !this.vm.taxRate[y].to_date) {
                  taxRate = this.vm.taxRate[y].rate;
                  break;
                } else if (rateFromDate <= headerDate && !this.vm.taxRate[y].to_date) {
                  taxRate = this.vm.taxRate[y].rate;
                  break;
                } else if (rateToDate >= headerDate && !this.vm.taxRate[y].from_date) {
                  taxRate = this.vm.taxRate[y].rate;
                  break;
                } else if (rateFromDate <= headerDate && rateToDate >= headerDate) {
                  taxRate = this.vm.taxRate[y].rate;
                  break;
                } else {
                  const badTaxCode = this.FilterObject(this.vm.tax, { id: this.vm.item.detail[x].tax_id_1 }, true)[0].code;
                  this.notification.showWarningToast(
                    $localize`:No tax rates found for @@notaxratesfoundfor:No tax rates found for` +
                      ' ' +
                      badTaxCode +
                      '. ' +
                      '</br>' +
                      $localize`: Last date authorized @@lastdateuauthorized: Last date authorized: ` +
                      ' ' +
                      headerDate
                  );
                }
              }
            }
          }

          taxTotal += Math.round(taxRate * this.vm.item.detail[x].amount * this.vm.item.detail[x].quantity * 100) / 100;
          taxAmount1 +=
            Math.round(taxRate * this.vm.item.detail[x].amount * this.vm.item.detail[x].quantity * 100) / 100;
        } // end of tax 1

        taxRate = 0;
        // tax 2
        if (this.vm.item.detail[x].tax_id_2) {
          this.vm.item.detail[x].taxCode_2 = taxCode = this.FilterObject(
            this.vm.tax,
            { id: this.vm.item.detail[x].tax_id_2 },
            true
          )[0].code;
          let taxOverride = false;
          if (this.vm.subLedger === 'ar' && this.vm.history) {
            if (this.vm.history.Taxes.receivable_tax_1_id !== undefined) {
              if (this.vm.history.Taxes.receivable_tax_1_id == this.vm.item.detail[x].tax_id_2) {
                taxRate = this.vm.history.Taxes.receivable_tax_1_rate;
                taxOverride = true;
              }
            } else if (this.vm.history.Taxes.receivable_tax_2_id !== undefined) {
              if (this.vm.history.Taxes.receivable_tax_2_id == this.vm.item.detail[x].tax_id_2) {
                taxRate = this.vm.history.Taxes.receivable_tax_2_rate;
                taxOverride = true;
              }
            }
          } else if (this.vm.history) {
            if (this.vm.history.Taxes.payable_tax_1_id !== undefined) {
              if (this.vm.history.Taxes.payable_tax_1_id == this.vm.item.detail[x].tax_id_2) {
                taxRate = this.vm.history.Taxes.payable_tax_1_rate;
                taxOverride = true;
              }
            } else if (this.vm.history.Taxes.payable_tax_2_id !== undefined) {
              if (this.vm.history.Taxes.payable_tax_2_id == this.vm.item.detail[x].tax_id_2) {
                taxRate = this.vm.history.Taxes.payable_tax_2_rate;
                taxOverride = true;
              }
            }
          }
          if (taxRate >= 1) {
            taxRate = taxRate / 100;
          }
          let rateFromDate;
          let headerDate;
          let rateToDate;

          if (!taxOverride) {
            for (const [y, vle] of Object.entries(this.vm.taxRate)) {
              // console.log('id passed in: ' + id);
              // console.log('tax array: ' + JSON.stringify(this.vm.tax));
              // console.log('taxe rates array: ' + JSON.stringify(this.vm.taxRate));
              // console.log('x: ' + x);
              // console.log('item det tax:' + this.vm.item.detail[x].tax_id_2);
              // console.log('item det tax2:' + JSON.stringify(this.vm.item.detail[x].tax_id_2));
              // console.log('tax rates:' + this.vm.taxRate[y].tax_id);
              // console.log('tax rates:' + JSON.stringify(this.vm.taxRate[y].tax_id));
              if (this.vm.item.detail[x].tax_id_2 == this.vm.taxRate[y].tax_id) {
                if (this.vm.taxRate[y].from_date) {
                  rateFromDate = new Date(this.vm.taxRate[y].from_date);
                } else {
                  rateFromDate = new Date('2000-01-01');
                }
                if (this.vm.taxRate[y].to_date) {
                  rateToDate = new Date(this.vm.taxRate[y].to_date);
                } else {
                  rateToDate = new Date('2100-12-31');
                }

                if (this.vm.item.header.header_date) {
                  headerDate = new Date(this.vm.item.header.header_date);
                } else {
                  headerDate = new Date();
                  this.notification.showErrorToast(
                    $localize`:No date selected. @@nodateselected:No date selected.` +
                      '<br/>' +
                      $localize`:Tax rates calculated using today\'s date. @@taxratescalculatedusingtodaysdate:Tax rates calculated using today\'s date.`
                  );
                  this.vm.dateMissing = true;
                }

                if (!this.vm.taxRate[y].from_date && !this.vm.taxRate[y].to_date) {
                  taxRate = this.vm.taxRate[y].rate;
                  break;
                } else if (rateFromDate <= headerDate && !this.vm.taxRate[y].to_date) {
                  taxRate = this.vm.taxRate[y].rate;
                  break;
                } else if (rateToDate >= headerDate && !this.vm.taxRate[y].from_date) {
                  taxRate = this.vm.taxRate[y].rate;
                  break;
                } else if (rateFromDate <= headerDate && rateToDate >= headerDate) {
                  taxRate = this.vm.taxRate[y].rate;
                  break;
                } else {
                  const badTaxCode2 = this.FilterObject(this.vm.tax, { id: this.vm.item.detail[x].tax_id_2 }, true)[0]
                    .code;
                  this.notification.showWarningToast(
                    $localize`:No tax rates found for @@notaxratesfoundfor:No tax rates found for` +
                      ' ' +
                      badTaxCode2 +
                      '. ' +
                      '<br/>' +
                      $localize`: Last date authorized @@lastdateuauthorized: Last date authorized: ` +
                      ' ' +
                      headerDate
                  );
                }
              }
            }
          }

          taxTotal += Math.round(taxRate * this.vm.item.detail[x].amount * this.vm.item.detail[x].quantity * 100) / 100;
          taxAmount2 +=
            Math.round(taxRate * this.vm.item.detail[x].amount * this.vm.item.detail[x].quantity * 100) / 100;
        } // end of tax 2

        this.vm.item.detail[x].tax_amount_1 = taxAmount1;
        this.vm.item.detail[x].tax_amount_2 = taxAmount2;

        this.calcTotal();
        break;
      }
    }
  }

  changeLocation(event: any) {
    this.vm.item.header.location_id = event;
    this.vm.request.location_id = event;
    if (this.vm.item.header.company_id == undefined && this.vm.Preferences.default_company_id) {
      this.vm.item.header.company_id = this.vm.Preferences.default_company_id;
    }
  }

  printDocument() {
    this.printService.printDocument('invoice-print-ar', this.documentId, this.vm.item.header.company_id, this.vm.subLedger);
  }

  async print() {
    // this.notification.showInfoToast('Creating document to print ...');
    this.vm.action = 'print';
    // const data = await this.setInvoicePrintValues();
  }

  encodeQueryData(data, filterEmpty = true) {
    const ret = [];
    for (const d in data) {
      if (filterEmpty) {
        if (data[d] != null && data[d] != '') {
          ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
        }
      } else {
        if (data[d] !== undefined) {
          ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
        }
      }
    }
    return ret.join('&');
  }

  // todo unlikely we want to allow adds from the form itself - not an approved inventory item etc. and bad process for additions
  getItemsToOrder() {
    this.vm.itemsToOrder = [];
    this.vm.itemPO = [];

    setTimeout(() => {
      this.vm.buttonsDisabled = true;
    }, 500);

    this.tableDataService
      .getTableData(
        'ItemPO',
        '?' +
          this.encodeQueryData({
            location_id: this.vm.item.header.location_id,
            company_id: this.vm.item.header.company_id,
            toDate: this.vm.item.header.header_date,
          })
      )
      .subscribe((ItemPO: any[]) => {
        this.vm.itemPO = ItemPO;
        this.tableDataService
          .getTableData(
            'ItemQuantity/locationid',
            '?' +
              this.encodeQueryData({
                location_id: this.vm.item.header.location_id,
              })
          )
          .subscribe((ItemQuantity: any[]) => {
            this.vm.itemQuantities = ItemQuantity;
            this.tableDataService
              .getTableData(
                'InventoryBalance',
                '?' +
                  this.encodeQueryData({
                    location_id: this.vm.item.header.location_id,
                    company_id: this.vm.item.header.company_id,
                    toDate: this.vm.item.header.header_date,
                  })
              )
              .subscribe((InventoryBalance: any[]) => {
                this.vm.itemBalances = InventoryBalance;
                this.vm.noItemsToOrder = false;
                this.vm.buttonsDisabled = false;
                let tempItems = [];
                let tempItemPO = [];
                if (this.vm.itemBalances && this.vm.itemBalances.length == 1) {
                  tempItems = this.vm.itemBalances[0].locations[0].items;
                }
                if (this.vm.itemPO.length > 0) {
                  tempItemPO = this.vm.itemPO[0].locations[0].items;
                }
                for (const [x, value] of Object.entries(this.vm.itemQuantities)) {
                  let itemPOAmount = 0;
                  if (
                    this.cFilter.transform(tempItemPO, { item_id: this.vm.itemQuantities[x].item_id }, true)[0] !==
                    undefined
                  ) {
                    itemPOAmount = this.cFilter.transform(
                      tempItemPO,
                      { item_id: this.vm.itemQuantities[x].item_id },
                      true
                    )[0].quantity;
                  }
                  if (
                    this.cFilter.transform(tempItems, { item_id: this.vm.itemQuantities[x].item_id }, true)[0] !==
                    undefined
                  ) {
                    const tempItemBalance = this.cFilter.transform(
                      tempItems,
                      { item_id: this.vm.itemQuantities[x].item_id },
                      true
                    )[0];
                    if (tempItemBalance.quantity < this.vm.itemQuantities[x].minimum_on_hand_quantity) {
                      let tempQuantity = this.vm.itemQuantities[x].maximum_on_hand_quantity - tempItemBalance.quantity;
                      tempQuantity = tempQuantity - itemPOAmount;
                      if (tempQuantity < this.vm.itemQuantities[x].minimum_order_quantity && itemPOAmount === 0) {
                        tempQuantity = this.vm.itemQuantities[x].minimum_order_quantity;
                      }
                      if (tempQuantity > 0) {
                        this.vm.itemsToOrder.push({
                          item_id: tempItemBalance.item_id,
                          item_code: tempItemBalance.item_code,
                          item_name: tempItemBalance.item,
                          quantity: tempQuantity,
                          quantity_on_hand: tempItemBalance.quantity,
                          quantity_on_po: itemPOAmount,
                          minimum_on_hand_quantity: this.vm.itemQuantities[x].minimum_on_hand_quantity,
                          maximum_on_hand_quantity: this.vm.itemQuantities[x].maximum_on_hand_quantity,
                          minimum_order_quantity: this.vm.itemQuantities[x].minimum_order_quantity,
                          include: true,
                        });
                      }
                    }
                  } else {
                    let tempQuantity = this.vm.itemQuantities[x].maximum_on_hand_quantity;
                    tempQuantity = tempQuantity - itemPOAmount;
                    if (tempQuantity < this.vm.itemQuantities[x].minimum_order_quantity && itemPOAmount === 0) {
                      tempQuantity = this.vm.itemQuantities[x].minimum_order_quantity;
                    }
                    if (tempQuantity > 0) {
                      this.vm.itemsToOrder.push({
                        item_id: this.vm.itemQuantities[x].item_id,
                        item_code: this.vm.itemQuantities[x].item_code,
                        item_name: this.vm.itemQuantities[x].item_name,
                        quantity: tempQuantity,
                        quantity_on_hand: 0,
                        quantity_on_po: itemPOAmount,
                        minimum_on_hand_quantity: this.vm.itemQuantities[x].minimum_on_hand_quantity,
                        maximum_on_hand_quantity: this.vm.itemQuantities[x].maximum_on_hand_quantity,
                        minimum_order_quantity: this.vm.itemQuantities[x].minimum_order_quantity,
                        include: true,
                      });
                    }
                  }
                }
                if (this.vm.itemsToOrder.length === 0) {
                  this.vm.noItemsToOrder = true;
                }
              });
          });
      });
  }

  generateItemsToOrder() {
    let sortOrder = -100;
    let tempItem;
    for (const [a, value] of Object.entries(this.vm.itemsToOrder)) {
      if (this.vm.itemsToOrder[a].include) {
        tempItem = this.FilterObject(this.vm.inventoryItem, { id: this.vm.itemsToOrder[a].item_id }, true)[0];
        if (tempItem !== undefined) {
          this.vm.item.detail.push({
            item_id: this.vm.itemsToOrder[a].item_id,
            quantity: this.vm.itemsToOrder[a].quantity,
            sort_order: sortOrder,
            itemInput: tempItem.code,
            id: sortOrder,
          });
          this.selectItemDropdown(sortOrder);
          sortOrder++;
        }
      }
    }
    this.vm.item.detail = this.OrderBy(this.vm.item.detail, 'sort_order', false);
  }

  emailDocument() {
    this.setEmailData();
    // start the email modal
    const element = document.getElementById('sendEmailModal') as HTMLElement;
    const emailModal = new Modal(element);
    emailModal.show();

  }

  setEmailData() {
    // console.log('sl: ' + this.vm.subLedger);

    // needed for switch - only knows primitives
    const compVal = this.vm.subLedger;

    switch (compVal) {
      case 'ap':
        this.emailDocumentName = 'Payable Invoice';
        break;
      case 'ar':
        this.emailDocumentName = 'Receivable Invoice';
        break;
      case 'so':
        this.emailDocumentName = 'Sales Order';
        break;
      case 'salesorders':
        this.emailDocumentName = 'Sales Order';
        break;
      case 'sq':
        this.emailDocumentName = 'Sales Quote';
        break;
      case 'salesquotes':
        this.emailDocumentName = 'Sales Quote';
        break;
      case 'po':
        this.emailDocumentName = 'Purchase Order';
        break;
      case 'purchaseorders':
        this.emailDocumentName = 'Purchase Order';
        break;
      default:
        this.emailDocumentName = 'document';
        break;
    }

    const emailDocObj = {} as EmailModel;

    emailDocObj.fromEmail = '';
    emailDocObj.fromName = 'AcctXErp';
    emailDocObj.header_id = this.documentId;
    emailDocObj.toEmail = '';
    emailDocObj.body = 'Please see the attached ' + this.emailDocumentName + '.<br><br><br>'
      + 'Best regards,'
      + '<br><br>'
      + 'AcctXErp'
      + '<br><br>';
    emailDocObj.Subject = 'AcctXErp ' + this.emailDocumentName;
    emailDocObj.pdfHtml = this.emailReportDataFromAppInvoiceEmailEmit;
    emailDocObj.activity_message = this.emailDocumentName;

    this.reportDataToSendInEmail = emailDocObj;
  }
  isFieldEmpty(value: any): boolean {
    let retVal = false;
    if (!value) {
      retVal = true;
    } else {
      retVal = false;
    }
    return retVal;
  }

  async loadItem() {
    await this.getTableDataAsync('item', '?state=active', 'inventoryItem', (value) => {
      this.vm.inventoryItem = value;
    });
  }

  checkCutOffDate(dateToCheck: Date): boolean {
    if (!dateToCheck) {
      return;
    }
    // console.log('in checkCutoffDate: ' + dateToCheck);
    const cutOffDate = new Date(this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0].cut_off_date);
    // console.log('in checkCutoffDate - cutOffDate =: ' + cutOffDate);
    if (dateToCheck.getTime() < cutOffDate.getTime()) {
      // this.vm.alert.status = 'error';
      this.notification.showWarningToast(
        $localize`:Date must after the cut off date @@datemustbeafterthecutoffdate:Date must be after the cut off date` +
          ' ' +
          cutOffDate.toLocaleDateString()
      );
      return true;
    }
  }

  savePayment(headerId: any) {
    if (!this.checkCutOffDate(this.vm.payment.date)) {
      return;
    }

    // if nothing to post - return
    // console.log('applied array at savePayment: ' + JSON.stringify(this.vm.item.applied));
    // if (this.vm.item.applied === undefined || this.vm.item.applied === null) {
    //   return;
    // }
    if (!this.vm.item.applied) {
      return;
    }

    // if prior payments posted stop - can only post one from here
    // template should catch it first - JIC it does not
    if (this.vm.item.applied.length > 0) {
      this.notification.showWarningToast('Can only post 1 payment from this page.');
      return;
    }

    if (this.vm.paymentAmount && (this.vm.permissions.Admin || this.vm.permissions.EditReceivablePayments)) {
      this.vm.payment.header.company_id = this.vm.item.header.company_id;
      this.vm.payment.header.currency_rate = 1;
      this.vm.payment.header.currency_id = this.vm.item.header.currency_id;
      this.vm.payment.header.entity_id = this.vm.item.header.entity_id;
      this.vm.payment.header.description = 'Payment entered from Invoice Entry';
      this.vm.payment.detail[0].account_id = this.FilterObject(
        this.vm.bank,
        { id: this.vm.payment.header.bank_id },
        true
      )[0].account_id;
      this.vm.payment.detail[0].amount = this.vm.paymentAmount;
      if (this.vm.subLedger === 'ar') {
        this.vm.payment.detail[0].debit_credit = this.vm.paymentAmount;
      } else {
        this.vm.payment.detail[0].debit_credit = 0 - this.vm.paymentAmount;
      }
      if (this.vm.paymentAmount > this.vm.total - Number(this.vm.item.header.payment_applied_total)) {
        this.vm.appliedAmount = this.vm.total - Number(this.vm.item.header.payment_applied_total);
      } else {
        this.vm.appliedAmount = this.vm.paymentAmount;
      }
      this.vm.payment.applied = [
        { applied_header_id: headerId, applied_amount: this.decimalPipe.transform(this.vm.appliedAmount) },
      ];
      // console.log('posting payment: ' + JSON.stringify(this.vm.payment));
      this.tableDataService
        .post('entry', this.vm.payment)
        .pipe(take(1))
        .subscribe({
          next: (item: any) => {
            this.vm.alert.status = 'success';
            this.vm.buttonsDisabled = false;
            this.vm.item.header.payment_applied_total =
              Number(this.vm.item.header.payment_applied_total) + Number(this.vm.paymentAmount);
            this.vm.paymentAmount = '';
          },
          error: (error: { error: { Message: string }; statusText: string }) => {
            this.vm.buttonsDisabled = false;
            this.vm.alert.status = 'error';

            let ErrorMsg: string;
            if (typeof error.error.Message != 'undefined') {
              ErrorMsg = error.error.Message;
            } else {
              ErrorMsg = error.statusText;
            }
            console.log(ErrorMsg);
          },
          complete: () => {
            // console.log('subledger: ' + this.vm.subLedger);
            if (this.vm.subLedger === 'ap') {
              this.router.navigateByUrl('/transactions/invoice/form?invoiceId=' + this.vm.item.header.id).then();
            }
            if (this.vm.subLedger === 'ar') {
              this.router.navigateByUrl('/transactions/invoice/form?invoiceId=' + this.vm.item.header.id).then();
            }
          },
        });
    }
  }

  save() {
    this.vm.alert = {};
    this.vm.alert.message = [];

    this.submitted = true;

    // check errors
    if (
      this.isFieldEmpty(this.vm.item.header.entityInput) ||
      this.isFieldEmpty(this.vm.item.header.bill_address_id) ||
      this.isFieldEmpty(this.vm.item.header.header_date) ||
      this.isFieldEmpty(this.vm.item.header.company_id) ||
      this.isFieldEmpty(this.vm.item.header.currency_id) ||
      this.isFieldEmpty(this.vm.item.header.location_id) ||
      this.isFieldEmpty(this.vm.item.header.term_id) ||
      this.isFieldEmpty(this.vm.item.header.description) ||
      (this.isFieldEmpty(this.vm.payment.header.number) &&
        this.vm.paymentAmount != undefined &&
        this.vm.paymentAmount != '')
    ) {
      this.notification.showWarningToast($localize`:Form Errors @@formErrors:From Errors`);
      return;
    }

    this.testEntity();

    if (!this.vm.newInvoice && !this.vm.closeInvoice) {
      this.vm.saveInvoice = true;
    }
    if (this.vm.subLedger == 'ar') {
      // necessary due to very rare bug
      for (const [x, value] of Object.entries(this.vm.item.detail)) {
        this.calcTax(this.vm.item.detail[x].id).then();
      }
    }
    if (this.vm.subLedger === 'ap') {
      let tempInvoice = [];
      if (this.vm.item.header.id && this.vm.history.Transactions) {
        // tempInvoice = this.FilterObject(this.vm.history.Transactions, { batch_type_id: 4, number: this.vm.item.header.number }, true);
        tempInvoice = this.vm.history.Transactions.filter(
          (t) => t.batch_type_id == 4 && t.number == this.vm.item.header.number
        );
        for (let x = tempInvoice.length - 1; x > -1; x--) {
          if (tempInvoice[x].id == this.vm.item.header.id) {
            tempInvoice.splice(x, 1);
          }
        }
      } else if (this.vm.item.header.number && this.vm.history.Transactions) {
        // tempInvoice = this.FilterObject(this.vm.history.Transactions, { batch_type_id: 4, number: this.vm.item.header.number }, true);
        tempInvoice = this.vm.history.Transactions.filter(
          (t) => t.batch_type_id == 4 && t.number == this.vm.item.header.number
        );
      }

      if (tempInvoice.length > 0) {
        this.vm.alert.status = 'error';
        this.notification.showWarningToast(
          $localize`:Duplicate Invoice Number @@duplicateInvoiceNumber:Duplicate Invoice Number`
        );
        // this.vm.alert.message.push({
        //   text: $localize`:Duplicate Invoice Number @@duplicateInvoiceNumber:Duplicate Invoice Number`,
        // });
        this.vm.numberError = true;
      }
    }

    if (!this.vm.item.header.header_date) {
      // this.vm.alert.status = 'error';
      // this.vm.alert.message.push({
      //   text: $localize`:Date is required. @@dateisrequired:Date is required.`,
      // });
      this.vm.dateMissing = true;
    } else if (this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0]) {
      const yearEndDate = this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0]
        .year_end_date;
      const cutOffDate = this.FilterObject(this.vm.company, { id: this.vm.item.header.company_id }, true)[0]
        .cut_off_date;
      const tempYearEndDate = new Date(yearEndDate);
      const tempCutOffDate = new Date(cutOffDate);
      const headerDate = new Date(this.vm.item.header.header_date);
      if (headerDate < tempYearEndDate) {
        this.vm.alert.status = 'error';
        this.notification.showWarningToast(
          $localize`:Date must be greater than the year end @@datemustbegreaterthantheyearend:Date must be greater than the year end` +
            ' ' +
            yearEndDate
        );
        // this.vm.alert.message.push({
        //   text:
        //     $localize`:Date must be greater than the year end @@datemustbegreaterthantheyearend:Date must be greater than the year end` +
        //     ' ' +
        //     yearEndDate,
        // });
      }
      if (headerDate < tempCutOffDate) {
        this.vm.alert.status = 'error';
        this.notification.showWarningToast(
          $localize`:Date must be greater than the cut off date @@datemustbegreaterthanthecutoffdate:Date must be greater than the cut off date` +
            ' ' +
            cutOffDate
        );
      }
    } else {
      // this.vm.alert.status = 'error';
      // this.vm.alert.message.push({
      //   text: $localize`:Company is required @@companyisrequired:Company is required`,
      // });
      this.vm.companyError = true;
    }

    if (!this.vm.item.header.location_id) {
      // this.vm.alert.status = 'error';
      // this.vm.alert.message.push({
      //   text: $localize`:Location is required. @@locationisrequired:Location is required.`,
      // });
      this.vm.locationError = true;
    }
    if (!this.vm.item.header.due_date) {
      // this.vm.alert.status = 'error';
      // this.vm.alert.message.push({
      //   text: $localize`:Term is required. @@termisrequired:Term is required.`,
      // });
      this.vm.locationError = true;
    }
    // vm.item.header.description
    if (!this.vm.item.header.description) {
      // this.vm.alert.status = 'error';
      // this.vm.alert.message.push({
      //   text: $localize`:Term is required. @@termisrequired:Term is required.`,
      // });
      this.vm.locationError = true;
    }

    if (!this.vm.item.header.currency_id) {
      // this.vm.alert.status = 'error';
      // this.vm.alert.message.push({
      //   text: $localize`:Currency is required. @@currencyisrequired:Currency is required.`,
      // });
      this.vm.termError = true;
    }

    if (this.vm.subLedger === 'ap') {
      // console.log('post ap');
      if (!this.vm.item.header.number) {
        this.vm.alert.status = 'error';
        // this.vm.alert.message.push({
        //   text: $localize`:Number is required. @@numberisrequired:Number is required.` + '</br>',
        // });
        this.notification.showWarningToast($localize`:Number is required. @@numberisrequired:Number is required.`);
        this.vm.numberError = true;
      } else {
        this.vm.item.header.number = this.vm.item.header.number.toUpperCase();
        // check for duplicate
      }
    }

    let detailFound = false;
    for (const [x, value] of Object.entries(this.vm.item.detail)) {
      this.vm.item.detail[x].debit_credit = this.vm.item.detail[x].quantity * this.vm.item.detail[x].amount;

      if (this.vm.item.detail[x].amount < 0 && this.vm.item.detail[x].item_id) {
        this.vm.alert.status = 'error';
        this.notification.showWarningToast(
          $localize`:Amounts cannot be negative on lines with items. Please use a negative quantity. @@amountscannotbenegativeonlineswithitemsPleaseuseanegativequantity:Amounts cannot be negative on lines with items. Please use a negative quantity.`
        );
      }
      // Additional
      this.vm.item.detail[x].debit_credit = this.vm.item.detail[x].amount * this.vm.item.detail[x].quantity;

      if (!this.vm.item.detail[x].debit_credit) {
        this.vm.item.detail[x].debit_credit = 0;
      }
      if (this.vm.item.detail[x].debit_credit) {
        detailFound = true;

        if (!this.vm.item.detail[x].description) {
          this.vm.alert.status = 'error';
          this.notification.showWarningToast(
            $localize`:Detail description is required. @@detaildescriptionisrequired:Detail description is required.`
          );
          // this.vm.alert.message.push({
          //   text: $localize`:Detail description is required. @@detaildescriptionisrequired:Detail description is required.`,
          // });
        }
        if (!this.vm.item.detail[x].account_id && (this.vm.subLedger === 'ap' || this.vm.subLedger == 'ar')) {
          this.vm.alert.status = 'error';
          this.notification.showWarningToast(
            $localize`:Detail account is required. @@detailaccountisrequired:Detail account is required.`
          );
          // this.vm.alert.message.push({
          //   text: $localize`:Detail account is required. @@detailaccountisrequired:Detail account is required.`,
          // });
        }
      }
    }
    if (!detailFound) {
      this.vm.alert.status = 'error';
      this.notification.showWarningToast(
        $localize`:At least one detail row is required. @@atleastonedetailrowisrequired:At least one detail row is required.`
      );
      // this.vm.alert.message.push({
      //   text: $localize`:At least one detail row is required. @@atleastonedetailrowisrequired:At least one detail row is required.`,
      // });
    }

    if (this.vm.paymentAmount) {
      if (!this.vm.payment.header.bank_id) {
        this.vm.alert.status = 'error';
        this.notification.showWarningToast(
          $localize`:You have entered a payment amount, a Bank Account is required. @@youhaveenteredapaymentamountaBankAccountisrequired:You have entered a payment amount, a Bank Account is required.`
        );
        this.vm.bankError = true;
      }
      if (!this.vm.payment.header.payment_type_id) {
        this.vm.alert.status = 'error';
        this.notification.showWarningToast(
          $localize`:You have entered a payment amount, a Payment Type is required. @@youhaveenteredapaymentamountaPaymentTypeisrequired:You have entered a payment amount, a Payment Type is required.`
        );
        this.vm.paymentTypeError = true;
      }
      // console.log('dates: ' + JSON.stringify(this.vm.payment.header));
      if (!this.vm.payment.header.header_date) {
        this.vm.alert.status = 'error';
        this.notification.showWarningToast(
          $localize`:You have entered a payment amount, a Date is required. @@youhaveenteredapaymentamountaDateisrequired:You have entered a payment amount, a Date is required.`
        );
        this.vm.paymentDateError = true;
      }
    }
    if (
      !this.vm.permissions.Admin &&
      !this.vm.permissions.EditReceivableInvoices &&
      !this.vm.permissions.EditPayableInvoices &&
      !this.vm.permissions.EditQuotes &&
      !this.vm.permissions.EditSalesOrders &&
      !this.vm.permissions.EditPurchaseOrders
    ) {
      this.vm.alert.status = 'error';
      this.notification.showWarningToast(
        $localize`:You do not have access to save. @@youdonothaveaccesstosave:You do not have access to save.`
      );
      // this.vm.alert.message.push({
      //   text: $localize`:You do not have access to save. @@youdonothaveaccesstosave:You do not have access to save.`,
      // });
      return;
    }

    if (this.vm.alert.status != 'error') {
      // this.vm.buttonsDisabled = true;
      if (!this.vm.item.header.signature || this.vm.item.header.signature === '') {
        // var ele: HTMLInputElement = <HTMLInputElement>document.getElementById('signatureValue');
        // this.vm.item.header.signature = ele.value;
        if (this.vm.linkedSignature) {
          this.vm.item.header.signature = this.vm.linkedSignature;
        }
      }
      if (this.vm.item.header.id == undefined) {
        // new
        this.vm.item.header.header_date = new Date(this.vm.item.header.header_date).toISOString();
        if (!this.vm.item.header.term_id) {
          // term not selected - set due date same as header date - should not happen
          this.vm.item.header.due_date = new Date(this.vm.item.header.header_date);
        }
        // console.log('posting this: ' + JSON.stringify(this.vm.item));
        this.tableDataService.post('entry', this.vm.item).pipe().subscribe({
          next: (item: any) => {
            this.savePayment(item.id);
            if (this.vm.saveInvoice) {
              this.vm.item.header.id = item.id;
              if (this.vm.subLedger === 'ap') {
                this.vm.item.batchTypeId = 4;
                // TODO this looks odd, but the router changes in 15 sort of force this. Tried sub to url params and no go.
                // so reset url user sees then fetch dat for the posted item manual since angular wont rerun the page on param chg
                this.router.navigateByUrl('/transactions/invoice/form?docmentId=' + item.id).then();
                this.getDocumentOnSave(item.id);
              } else if (this.vm.subLedger === 'ar') {
                this.vm.item.batchTypeId = 2;
                this.documentId = item.id;
                this.router.navigateByUrl('/transactions/invoice/form?documentId=' + item.id).then();
                this.getDocumentOnSave(item.id);
              } else if (this.vm.subLedger === 'quotes') {
                this.vm.item.batchTypeId = 8;
                this.router.navigateByUrl('/transactions/invoice/form?quoteId=' + item.id).then();
                this.getDocumentOnSave(item.id);
              } else if (this.vm.subLedger === 'salesorders') {
                this.vm.item.batchTypeId = 9;
                this.router.navigateByUrl('/transactions/invoice/form?documentId=' + item.id).then();
                this.getDocumentOnSave(item.id);
              } else if (this.vm.subLedger === 'purchaseorders') {
                this.vm.item.batchTypeId = 10;
                this.router.navigateByUrl('/transactions/invoice/form?documentId=' + item.id).then();
                this.getDocumentOnSave(item.id);
              }
            }
            if (this.vm.newInvoice) {
              this.vm.item.attachments = [];
              this.vm.total = 0;
              this.vm.subtotal = 0;
              this.vm.newInvoice = false;
              this.vm.history = {};
              this.vm.billToAddress = '';
              this.vm.shipToAddress = '';
              this.vm.contact = '';
              this.vm.buttonsDisabled = false;
              // this.documentId = item.id;
              if (this.vm.subLedger === 'ap') {
                this.vm.item.batchTypeId = 4;
                this.router.navigateByUrl('/transactions/invoice/form?documentId=newAP').then();
              } else if (this.vm.subLedger === 'ar') {
                this.vm.item.batchTypeId = 2;
                this.router.navigateByUrl('/transactions/invoice/form?documentId=newAR').then();
              } else if (this.vm.subLedger === 'quotes') {
                this.vm.item.batchTypeId = 8;
                this.router.navigateByUrl('/transactions/invoice/form?documentId=newQuote').then();
              } else if (this.vm.subLedger === 'salesorders') {
                this.vm.item.batchTypeId = 9;
                this.router.navigateByUrl('/transactions/invoice/form?documentId=newSalesOrder').then();
              } else if (this.vm.subLedger === 'purchaseorders') {
                this.vm.item.batchTypeId = 10;
                this.router.navigateByUrl('/transactions/invoice/form?documentId=newPurchaseOrder').then();
              }
              this.vm.paid = false;
              this.init().then();
              this.vm.is_init = true;
            }
            if (this.vm.closeInvoice) {
              // this.router.navigateByUrl('/transactions/invoice/list/ar?type=ar&subLedger=ar');
              if (
                this.vm.item.batch.type_id == 2 &&
                (this.vm.item.header.recurring == false || !this.vm.item.header.recurring)
              ) {
                this.router.navigateByUrl('/transactions/invoice/list/ar?type=ar').then();
              } else if (
                this.vm.item.batch.type_id == 4 &&
                (this.vm.item.header.recurring == false || !this.vm.item.header.recurring)
              ) {
                this.router.navigateByUrl('/transactions/invoice/list/ap?type=ap').then();
              } else if (this.vm.item.batch.type_id == 8) {
                this.router.navigateByUrl('/transactions/invoice/list/quotes?type=quotes').then();
              } else if (this.vm.item.batch.type_id == 9) {
                this.router.navigateByUrl('/transactions/invoice/list/salesorders?type=salesorders').then();
              } else if (this.vm.item.batch.type_id == 10) {
                this.router.navigateByUrl('/transactions/invoice/list/purchaseorders?type=purchaseorders').then();
              } else if (this.vm.item.batch.type_id == 4 && this.vm.item.header.recurring == true) {
                this.router.navigateByUrl('/transactions/invoice/recurring/ap?type=ap&subLedger=ap').then();
              } else if (this.vm.item.batch.type_id == 2 && this.vm.item.header.recurring == true) {
                this.router.navigateByUrl('/transactions/invoice/recurring/ar?type=ar&subLedger=ar').then();
              }
              this.vm.closeInvoice = false;
            }
            if (this.vm.shipOrder) {
              if (this.vm.subLedger === 'salesorders') {
                this.router.navigateByUrl('/transactions/invoice/form?shipmentId=newSS&orderId=' + item.id).then();
              } else {
                this.router.navigateByUrl('/transactions/invoice/form?shipmentId=newPS&orderId=' + item.id).then();
              }
            } else if (this.vm.invoiceQuote) {
              this.router.navigateByUrl('/transactions/invoice/form?invoiceId=newAR&quoteId=' + item.id).then();
            } else if (this.vm.orderQuote) {
              this.router.navigateByUrl('/transactions/invoice/form?invoiceId=newSalesOrder&quoteId=' + item.id).then();
            }
          },
          error:  (error: any) => {
            this.vm.buttonsDisabled = false;
            let errorMsg: string;
            if (typeof error.error.Message != 'undefined') {
              errorMsg = error.error.Message;
            } else {
              errorMsg = error.statusText;
            }
            // this.notification.error(ErrorMsg);
            console.log(errorMsg);
          },
          complete: () => {
            // do stuff on completion
          }
        });
      } else {
        // edit
        if (!this.vm.item.header.signature) {
          if (this.vm.linkedSignature) {
            this.vm.item.header.signature = this.vm.linkedSignature;
          }
        }
        const params = {
          id: this.vm.item.header.id,
          tablename: 'entry',
        };

        // diff
        const changes: any = {};

        changes.detail = this.vm.item.detail;
        changes.salesperson = this.vm.item.salesperson;
        changes.attachments = this.vm.item.attachments;

        const headerChanges = {};
        let tempMonth: any;
        let tempDate: any;
        for (const key in this.vm.item.header) {
          if (this.vm.item.header.hasOwnProperty(key)) {
            let value = this.vm.item.header[key];
            if (value != key && key != 'entityInput') {
              if (key === 'header_date') {
                const d = new Date(value);
                if (d.getMonth() + 1 < 10) {
                  tempMonth = '0' + (d.getMonth() + 1);
                } else {
                  tempMonth = '' + (d.getMonth() + 1);
                }
                if (d.getDate() < 10) {
                  tempDate = '0' + d.getDate();
                } else {
                  tempDate = '' + d.getDate();
                }
                value = d.getFullYear() + '-' + tempMonth + '-' + tempDate;
              }
              headerChanges[key] = value;
            }
          }
        }
        if (headerChanges) {
          changes.header = headerChanges;
        }
        // dont save again if posted
        if (this.vm.buttonsDisabled) {
          // this.sendEmail();
        } else {
          // console.log('patching this: ' + JSON.stringify(changes));
          this.tableDataService
            .patchWithParamsNew('entry', this.vm.item.header.id, changes)
            .then((item: any) => {
              this.vm.buttonsDisabled = false;
              if (item.description === 'Deleted') {
                if (
                  this.vm.item.batch.type_id == 2 &&
                  (this.vm.item.header.recurring == false || !this.vm.item.header.recurring)
                ) {
                  this.router.navigateByUrl('/transactions/invoice/list/ar?type=ar').then();
                } else if (
                  this.vm.item.batch.type_id == 4 &&
                  (this.vm.item.header.recurring == false || !this.vm.item.header.recurring)
                ) {
                  this.router.navigateByUrl('/transactions/invoice/list/ap?type=ap').then();
                } else if (this.vm.item.batch.type_id == 8) {
                  this.router.navigateByUrl('/transactions/invoice/list/quotes?type=quotes').then();
                } else if (this.vm.item.batch.type_id == 9) {
                  this.router.navigateByUrl('/transactions/invoice/list/salesorders?type=salesorders').then();
                } else if (this.vm.item.batch.type_id == 10) {
                  this.router.navigateByUrl('/transactions/invoice/list/purchaseorders?type=purchaseorders').then();
                } else if (this.vm.item.batch.type_id == 4 && this.vm.item.header.recurring == true) {
                  this.router.navigateByUrl('/transactions/invoice/recurring/ap?type=ap&subLedger=ap').then();
                } else if (this.vm.item.batch.type_id == 2 && this.vm.item.header.recurring == true) {
                  this.router.navigateByUrl('/transactions/invoice/recurring/ar?type=ar&subLedger=ar').then();
                }
                // this.notification.success($localize`:Deleted@@deleted:Deleted`);
              } else if (this.vm.newInvoice) {
                this.savePayment(this.vm.item.header.id);
                // this.notification.success($localize`:Saved@@saved:Saved`);
                this.vm.item.attachments = [];
                this.vm.newInvoice = false;
                this.vm.contact = '';
                this.vm.history = {};
                this.vm.billToAddress = '';
                this.vm.shipToAddress = '';
                this.vm.paid = false;
                this.vm.is_init = false;
                this.vm.total = 0;
                this.vm.subtotal = 0;
                this.vm.item.header = [];
                this.submitted = false;
                if (this.vm.item.batch.type_id == 2) {
                  this.documentId = 'newAR';
                  this.router.navigateByUrl('/transactions/invoice/form?invoiceId=' + this.documentId).then();
                } else if (this.vm.item.batch.type_id == 4) {
                  this.documentId = 'newAP';
                  this.router.navigateByUrl('/transactions/invoice/form?documentId=' + this.documentId).then();
                } else if (this.vm.item.batch.type_id == 8) {
                  this.documentId = 'newQuote';
                  this.router.navigateByUrl('/transactions/invoice/form?documentId=' + this.documentId).then();
                } else if (this.vm.item.batch.type_id == 9) {
                  this.documentId = 'newSalesOrder';
                  this.router.navigateByUrl('/transactions/invoice/form?documentId=' + this.documentId).then();
                } else if (this.vm.item.batch.type_id == 10) {
                  this.documentId = 'newPurchaseOrder';
                  this.router.navigateByUrl('/transactions/invoice/form?documentId=' + this.documentId).then();
                }
              } else if (this.vm.closeInvoice) {
                // this.notification.success($localize`:Saved@@saved:Saved`);
                this.vm.closeInvoice = false;
                this.savePayment(this.vm.item.header.id);
                if (
                  this.vm.item.batch.type_id == 2 &&
                  (this.vm.item.header.recurring == false || !this.vm.item.header.recurring)
                ) {
                  this.router.navigateByUrl('/transactions/invoice/list/ar?type=ar').then();
                } else if (
                  this.vm.item.batch.type_id == 4 &&
                  (this.vm.item.header.recurring == false || !this.vm.item.header.recurring)
                ) {
                  this.router.navigateByUrl('/transactions/invoice/list/ap?type=ap').then();
                } else if (this.vm.item.batch.type_id == 8) {
                  this.router.navigateByUrl('/transactions/invoice/list/quotes?type=quotes').then();
                } else if (this.vm.item.batch.type_id == 9) {
                  this.router.navigateByUrl('/transactions/invoice/list/salesorders?type=salesorders').then();
                } else if (this.vm.item.batch.type_id == 10) {
                  this.router.navigateByUrl('/transactions/invoice/list/purchaseorders?type=purchaseorders').then();
                } else if (this.vm.item.batch.type_id == 4 && this.vm.item.header.recurring == true) {
                  this.router.navigateByUrl('/transactions/invoice/recurring/ap?type=ap&subLedger=ap').then();
                } else if (this.vm.item.batch.type_id == 2 && this.vm.item.header.recurring == true) {
                  this.router.navigateByUrl('/transactions/invoice/recurring/ar?type=ar&subLedger=ar').then();
                }
              } else if (this.vm.shipOrder) {
                if (this.vm.subLedger === 'salesorders') {
                  this.router
                    .navigateByUrl(
                      '/transactions/shipment/form?shipmentId=newSS&type=ar&orderId=' + this.vm.item.header.id
                    )
                    .then();
                } else {
                  this.router
                    .navigateByUrl(
                      '/transactions/shipment/form?shipmentId=newPS&type=ap&orderId=' + this.vm.item.header.id
                    )
                    .then();
                }
              } else if (this.vm.invoiceQuote) {
                this.router
                  .navigateByUrl('/transactions/invoice/list?invoiceId=newAR&quoteId=' + this.vm.item.header.id)
                  .then();
              } else if (this.vm.orderQuote) {
                this.router
                  .navigateByUrl('/transactions/invoice/list?invoiceId=newSalesOrder&quoteId=' + this.vm.item.header.id)
                  .then();
                // } else if (this.vm.subLedger === 'ap') {
                //   this.router
                //     .navigateByUrl('/transactions/invoice/form?invoiceId=' + this.vm.item.header.id)
                //     .then();
                // } else if (this.vm.subLedger === 'ar') {
                //   this.router
                //     .navigateByUrl('/transactions/invoice/form?invoiceId=' + this.vm.item.header.id)
                //     .then();
              } else {
                if (this.vm.action == 'print') {
                  window.print();
                  // console.log (window.document);
                } else if (this.vm.action === 'email') {
                  // this.sendEmail();
                }
                this.vm.action = '';
                if (item.description) {
                  if (item.Message === 'Success') {
                    // this.notification.success(item.description);
                  } else {
                    // console.log(item.description);
                  }
                }
                this.savePayment(this.vm.item.header.id);
                // updateAttachments();
              }
              this.vm.buttonsDisabled = false;
            })
            .catch((msg: string) => {
              this.vm.buttonsDisabled = false;
              // this.notification.showErrorToast(msg);
              console.log(msg);
            });
        }
      }
    } else {
      this.vm.buttonsDisabled = false;
    }
  }

  saveNew() {
    this.vm.newInvoice = true;
    this.save();
  }

  saveClose() {
    this.vm.closeInvoice = true;
    this.save();
  }

  onClose() {
    // send it back where it came from or if no route back close
    // console.log('prev url from service: ' + this.prevUrl.getPreviousUrl());
    // console.log('curr url from service: ' + this.prevUrl.getCurrentUrl());
    if (this.routedFrom) {
      this.router.navigateByUrl(this.routedFrom).then();
    } else if (this.routedFrom === '' || this.routedFrom === undefined) {
      window.close();
    }
  }

  generateShipment() {
    const brClass = this;
    const retMsg = '';
    const messages: string[] = ['Generate Shipment?'];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.vm.shipOrder = true;
        this.save();
        // this.router.navigateByUrl('/transactions/shipment/list/ar?type=shipments&subLedger=ar').then();
      },
      () => {
        brClass.notification.showSuccessToast('Delete Canceled');
      }
    );
  }

  generateInvoice() {
    const brClass = this;
    const retMsg = '';
    const messages: string[] = ['Are you sure you want to generate an invoice?', this.vm.item.header.number];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.vm.invoiceQuote = true;
        this.save();
      },
      () => {
        brClass.notification.showSuccessToast('Generate Canceled');
      }
    );
  }

  generateOrder() {
    const brClass = this;
    const retMsg = '';
    const messages: string[] = ['Are you sure you want to generate an order?', this.vm.item.header.number];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.vm.orderQuote = true;
        this.save();
      },
      () => {
        brClass.notification.showSuccessToast('Generate Canceled');
      }
    );
  }

  applyDefaultSettingsFromEntity() {
    if (!this.newDoc || !this.vm.history.Defaults) {
      return;
    }
    this.vm.item.header.term_id = this.vm.history.Defaults.term_id;
    this.vm.item.header.location_id = this.vm.history.Defaults.location_id;
    this.vm.payment.header.payment_type_id = this.vm.history.Defaults.payment_type_id;
    this.vm.item.header.term_id = this.vm.history.Defaults.term_id;
    this.vm.item.header.currency_id = this.vm.history.Defaults.currency_id;
    this.vm.item.header.company_id = this.vm.history.Defaults.company_id;

  }

  termChange() {
    if (this.vm.item.header.term_id && this.vm.item.header.header_date) {
      const daysDue = this.FilterObject(this.vm.term, { id: this.vm.item.header.term_id }, true)[0].due_days;
      const tempDate = new Date(this.vm.item.header.header_date);
      tempDate.setDate(tempDate.getDate() + daysDue);
      this.vm.item.header.due_date = tempDate;
    }
  }

  signature(signature) {
    // this.vm.linkedSignature = signature;
    this.vm.item.header.signature = signature;
    this.vm.getSignature = true;
    document.getElementById('closeSignatureModel').click();
    this.save();
  }

  deleteSignature() {
    this.vm.item.header.signature = '';
    this.vm.getSignature = true;
    document.getElementById('closeSignatureModel').click();
    this.save();
  }

  addTextSignature() {
    let text;
    if (this.vm.subLedger === 'ar' || this.vm.subLedger === 'ap') {
      text = 'Invoice ';
    }
    if (this.vm.subLedger === 'salesorders') {
      text = 'Sales Order ';
    }
    if (this.vm.subLedger === 'purchaseorders') {
      text = 'Purchase Order ';
    }
    if (this.vm.subLedger === 'quote') {
      text = 'Quote ';
    }
    if (this.vm.item.header.number) {
      text += 'Number: ' + this.vm.item.header.number + '      ';
    }
    let itemCount = 0;
    for (const [x, value] of Object.entries(this.vm.item.detail)) {
      itemCount += Number(this.vm.item.detail[x].quantity);
    }
    text += 'Total: $' + this.decimalPipe.transform(this.vm.total);
    this.vm.addTextSignature = text;
  }

  gotAnUploadFile(file: any) {
    // console.log('in got a file: ' + file);
    console.log('in got a file: ');
}

syncItemAttachments(attachmentsArray: any) {
    // console.log('attachments array recvd in main');
    // console.log(attachmentsArray);

    // clear existing - will be put back because in array passed back in file upload array
    this.vm.item.attachments = [];

    // use the pieces needed for the api record
    attachmentsArray.forEach((x) => {
      this.vm.item.attachments.push({
        id: x.id,
        header_id: x.header_id,
        file_name: x.fileName,
        type: x.fileType,
        attachment: x.fileData,
        audit_sequence: 0
      });
  });
    // console.log('main vm array after sync to file upload data');
    // console.log(this.vm);
}

  FilterObject(value, filterObj, actual = false) {
    return this.cFilter.transform(value, filterObj, actual);
  }

  OrderBy(array, field, reverse = true) {
    return new ArraySortPipe().transform(array, field, reverse);
  }

  clearEntity() {
    this.vm.item.header.entityInput = '';
    this.vm.item.header.ship_address_id = '';
    this.vm.item.header.bill_address_id = '';
    this.vm.item.header.contact_id = '';
  }

  removeComma() {
    if (
      !Number(this.vm.item.detail[0].amount) &&
      this.vm.item.detail[0].amount &&
      this.vm.item.detail[0].amount != '-' &&
      this.vm.item.detail[0].amount != '0'
    ) {
      let tempAmount = this.vm.item.detail[0].amount;
      tempAmount = tempAmount.replace(',', '');
      if (Number(tempAmount)) {
        this.vm.item.detail[0].amount = tempAmount;
      } else {
        this.vm.item.detail[0].amount = '';
      }
    }
  }

  deleteItem() {
    const brClass = this;
    const retMsg = '';
    const messages: string[] = ['Are you sure you want to delete invoice?', this.vm.item.header.number];
    this.confirmDialogService.confirmThis(
      messages,
      () => {
        this.vm.item.header.deleted = true;
        this.save();
      },
      () => {
        brClass.notification.showSuccessToast('Delete Canceled');
      }
    );
  }

  async getTableData(tableName: string, params: string, cacheName, fun) {
    // format various params due to the way this thing was built - should have been individual calls to comp not an internal method
    let queryChar = '&';
    if (tableName === 'account_subtotal_no_parents') {
      queryChar = '?';
    }
    this.vm.isLoaded = false;

    await this.tableDataService
      .getTableData(tableName, params + queryChar + 'pageNumber=0&pageSize=99999')
      .subscribe((result: ResponseModel) => {
        fun(result.Data);
      });
  }
}
