import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TableDataService } from '../../../Services/tableData.service';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from '../../../Services/print.service';
import { take} from '../../../../../node_modules/rxjs';
import { ChangeDetectorRef} from '@angular/core';
import {EmailModel} from '../../../DataModels/email-model';

@Component({
  selector: 'app-invoice-email',
  templateUrl: './invoice-email.component.html',
  styleUrls: ['./invoice-email.component.css'],
})
export class InvoiceEmailComponent implements OnInit, OnChanges, AfterViewInit {

  vm: any = {
    request: {},
    company: {},
  };
  public urlParams: any = [];
  isLoaded = false;
  companyLoaded = false;
  emailObject = new EmailModel();

  shipReceive: string;
  soldPurchase: string;

  constructor(
    private tableDataService: TableDataService,
    private route: ActivatedRoute,
    public print: PrintService,
    public changeDetectorRef: ChangeDetectorRef) {

  }

  @ViewChild('emailInvoice', { static: false, read: ElementRef }) InvoiceForm: ElementRef;

  @Input() documentId: any;
  @Input() companyId: any;
  @Input() subLedger: any;
  @Input() emailHeaderFromCaller: EmailModel;

  @Output() emailPDFHtml: EventEmitter<ElementRef> = new EventEmitter();

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes: any): void {
    // console.log('in email-invoice component onchange');
    // console.log('company: ' + this.companyId);
    // console.log('invoice: ' + this.documentId);
    if (this.companyId && this.documentId) {
      // console.log('running get data');
      this.getData(this.companyId, this.documentId, this.subLedger);
    }
  }

  getData(coId: string, docId: string, subLedger: string) {
    // values not passed in return - don't run the query
    if (!coId || !docId || !subLedger) {
      return;
    }
    // do nothing with dates right now - get by single item or via report page only for now
    const from = '1980-01-01';
    const to = '2050-12-31';

    this.vm.buttonsDisabled = true;
    this.vm.report_format = true;

    this.urlParams = this.route.snapshot.queryParams;

    this.vm.subLedger = subLedger;
    // console.log('subledger: ' + this.vm.subLedger);

    this.vm.type = this.urlParams.type;
    this.documentId = this.urlParams.documentId;
    this.companyId = this.urlParams.companyId;

    if (this.urlParams.batch_id) {
      // this.batchRecvdFlag = true;
      this.vm.batch = this.urlParams.batch_id;
      this.vm.batch_id = this.urlParams.batch_id;
    }

    this.vm.request.entity_id = this.urlParams.entity_id;

    this.setDocumentShipRecvSoldPurch();

    // console.log('company passed in was: ' + coId);

    this.tableDataService
      .getTableData('company', coId)
      .pipe(take(1))
      .subscribe({
        next: (result2: any) => {
          this.vm.company = result2;
          // console.log('company data from get data in invoice-email');
          // console.log(this.vm.company);
          this.companyLoaded = true;
        },
        error: (err: any) => {
          console.error(err.message);
        },
        complete: () => {
          // do stuff on complete
        },
      });

    const urlParamsJson = {
      fromDate: !this.vm.request.batch_id ? from : '',
      toDate: !this.vm.request.batch_id ? to : '',
      subLedger: this.vm.subLedger,
      entity_id: this.vm.request.entity_id,
      location_id: this.vm.request.location_id,
      company_id: this.vm.request.company_id,
      entity_type_id: this.vm.request.entity_type_id,
      invoice_id: docId ? docId : '',
      batch_id: this.vm.request.batch_id ? this.vm.request.batch_id : '',
    };

    const urlParams: string = this.encodeQueryData(urlParamsJson);
    // console.log('query from invoice email: ' + urlParams);

    this.tableDataService
      .getTableData('InvoiceReport', '?' + urlParams)
      .pipe(take(1))
      .subscribe({
        next: (result: any) => {
          this.vm.report = result;
          // console.log('document data result form invoice-email:' + JSON.stringify(this.vm.report));
          if (!this.vm.report.length) {
            // this.notification.showWarningToast('No data matching selection criteria');
            console.log('no data for invoice-email');
            return;
          }
          this.vm.showFilters = false;
          this.vm.buttonsDisabled = false;
          this.calcTotals();
        },
        error: (err: any) => {
          console.error(err.message);
        },
        complete: () => {
          // do stuff on complete
        },
      });
  }

  setDocumentShipRecvSoldPurch() {
    if (this.vm.subLedger == 'ap' || this.vm.subLedger == 'ps') {
      this.soldPurchase = 'From';
      this.shipReceive = 'Ship From';
    }

    if (this.vm.subLedger == 'ar'
      || this.vm.subLedger == 'ss'
      || this.vm.subLedger == 'so'
      || this.vm.subLedger == 'quotes'
      || this.vm.subLedger == 'salesquotes'
      || this.vm.subLedger == 'salesorders'
      || this.vm.subLedger == 'po'
      || this.vm.subLedger == 'purchaseorders') {

      this.soldPurchase = 'To';
      this.shipReceive = 'Ship To';
    }
  }

  calcTotals() {

    this.vm.reportTotal = 0;
    this.vm.reportSubtotal = 0;
    this.vm.reportTax1Code = '';
    this.vm.reportTax2Code = '';
    this.vm.reportTax3Code = '';
    this.vm.reportTax1Total = 0;
    this.vm.reportTax2Total = 0;
    this.vm.reportTax3Total = 0;
    let reverse = -1;
    if (this.vm.subLedger === 'ap') {
      reverse = 1;
    }

    for (const [x, value] of Object.entries(this.vm.report)) {
      this.vm.reportSubtotal += this.vm.report[x].amount;
      this.vm.reportTotal += this.vm.report[x].amount;
      this.vm.report[x].tax_total_1 = 0;
      this.vm.report[x].tax_total_2 = 0;
      for (const [y, value2] of Object.entries(this.vm.report[x].details)) {
        if (this.vm.reportTax1Code === '') {
          this.vm.reportTax1Code = this.vm.report[x].details[y].tax_1_code;
          this.vm.reportTax1Total += this.vm.report[x].details[y].tax_amount_1;
        } else if (this.vm.reportTax1Code === this.vm.report[x].details[y].tax_1_code) {
          this.vm.reportTax1Total += this.vm.report[x].details[y].tax_amount_1;
        } else if (this.vm.reportTax1Code === this.vm.report[x].details[y].tax_2_code) {
          this.vm.reportTax1Total += this.vm.report[x].details[y].tax_amount_2;
        }
        if (this.vm.reportTax2Code === '') {
          this.vm.reportTax2Code = this.vm.report[x].details[y].tax_2_code;
          this.vm.reportTax2Total += this.vm.report[x].details[y].tax_amount_2;
        } else if (this.vm.reportTax2Code === this.vm.report[x].details[y].tax_2_code) {
          this.vm.reportTax2Total += this.vm.report[x].details[y].tax_amount_2;
        } else if (this.vm.reportTax2Code === this.vm.report[x].details[y].tax_1_code) {
          this.vm.reportTax2Total += this.vm.report[x].details[y].tax_amount_1;
        }

        if (!this.vm.report[x].tax_code_1) {
          this.vm.report[x].tax_code_1 = this.vm.report[x].details[y].tax_1_code;
          this.vm.report[x].tax_total_1 += this.vm.report[x].details[y].tax_amount_1;
        } else if (this.vm.report[x].tax_code_1 === this.vm.report[x].details[y].tax_1_code) {
          this.vm.report[x].tax_total_1 += this.vm.report[x].details[y].tax_amount_1;
        } else if (this.vm.report[x].tax_code_1 === this.vm.report[x].details[y].tax_2_code) {
          this.vm.report[x].tax_total_1 += this.vm.report[x].details[y].tax_amount_2;
        }
        if (!this.vm.report[x].tax_code_2) {
          this.vm.report[x].tax_code_2 = this.vm.report[x].details[y].tax_2_code;
          this.vm.report[x].tax_total_2 += this.vm.report[x].details[y].tax_amount_2;
        } else if (this.vm.report[x].tax_code_2 === this.vm.report[x].details[y].tax_2_code) {
          this.vm.report[x].tax_total_2 += this.vm.report[x].details[y].tax_amount_2;
        } else if (this.vm.report[x].tax_code_2 === this.vm.report[x].details[y].tax_1_code) {
          this.vm.report[x].tax_total_2 += this.vm.report[x].details[y].tax_amount_1;
        }
        this.vm.reportTotal += this.vm.report[x].details[y].tax_amount_1 + this.vm.report[x].details[y].tax_amount_2;
      }
    }

    Object.assign(this.emailObject, this.emailHeaderFromCaller);

    this.changeDetectorRef.detectChanges();
    this.emailObject.pdfHtml = this.InvoiceForm.nativeElement.innerHTML;

    // console.log('email obj in invoice-email: ' + JSON.stringify(this.emailObject));

    // console.log('in calc one line before emit of pdfhtml');
    const lee = this.InvoiceForm.nativeElement as HTMLElement;
    console.log('lee: ' + lee.innerHTML );

    this.emailPDFHtml.emit(this.InvoiceForm.nativeElement.innerHTML);
  }

  encodeQueryData(data) {
    const ret = [];
    for (const d in data) {
      if (data[d] != null) {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  }
}
